/**
 * JS specific utils
 */
export default {
  methods: {

    /**
     * Creates a diff between the two given objects
     * Uses obj1's keys and values as the basis
     */
    createDiff(obj1, obj2) {
      const retObj = {};

      _.each(obj1, (v, k) => {
        if (!obj2) {
          retObj[k] = v;

        // Arrays get captured in the isObject check, so check them first
        } else if (_.isArray(v)) {
          if (!obj2[k]) retObj[k] = v;
          else if (!_.isEqual(v, obj2[k])) {
            const arr = [];
            _.each(v, (arrayItem, index) => {
              const comp = obj2[k][index];
              // Handle if v and comp are both strings/numbers
              if (_.isString(arrayItem) || _.isNumber(arrayItem)) {
                if (!_.isEqual(arrayItem, comp)) arr.push(arrayItem);
              } else {
                const subObject = this.createDiff(arrayItem, comp);
                if (!_.isEmpty(subObject)) arr.push(subObject);
              }
            });

            if (arr.length) retObj[k] = arr;
          }
        } else if (_.isObject(v)) {
          const subObject = this.createDiff(v, obj2[k]);

          if (!_.isEmpty(subObject)) retObj[k] = subObject;
        } else if (!_.isEqual(v, obj2[k])) {
          retObj[k] = v;
        }
      });

      return retObj;
    },

    /**
     * Simple parser for jwt tokens.
     * DOES NOT TAKE INTO ACCOUNT SECURITY
     * DO NOT USE THIS FOR ACTUAL SECURE CHECKING OF TOKENS
     */
    jwtParse(token, claim) {
      if (!token) return null;

      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
      }).join(''));
      const decoded = JSON.parse(jsonPayload);

      if (claim) return decoded[claim];
      return decoded;
    },

    setBlanksToNull(obj) {
      return _.mapValues(obj, (v) => {
        if (_.isObject(v)) return this.setBlanksToNull(v);
        if (v === '' || v === undefined) return null;
        return v;
      });
    }
  }
};
