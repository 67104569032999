/**
 * Currency filter
 * Returns given number in a standard currency format
 * e.g. 12 -> $12.00
 *
 * @param {number} value
 * @returns {string} Currency String
 */

import Vue from 'vue';
import { format } from 'd3';

export default function currencyFunction(input, symbol, places) {
  if (!_.isNumber(input)) return input;

  if (!symbol) symbol = '$';

  if (places === 0) places = 0; // don't overwrite 0, it is valid
  else if (!places || !_.isInteger(places)) places = 2;

  const roundingNumber = 10 ** places;
  const rounded = Math.round(input * roundingNumber) / roundingNumber;

  const formatted = format(`,.${places}f`)(rounded);

  if (rounded < 0) {
    return `-${symbol}${formatted.slice(1)}`;
  }
  return `${symbol}${formatted}`;
}

Vue.filter('currency', currencyFunction);
