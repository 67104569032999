import Vue from 'vue';
import Router from '@/router';
import Store from '@/store';

/**
 * Hides/Shows element if the user has permission to use the passed in service
 * Requires a getServiceMeta call on the component // TODO - move here?
 * @param Object service meta object
 *
 * E.g. v-show-if-service-permission="getServiceMeta('updateCoach')"
 */
Vue.directive('show-if-service-permission', (el, binding) => {
  const { securable, permission, owner } = binding.value;
  const hasPermission = Store.getters['permissions/hasPermission'](securable, permission, owner);

  el.classList.toggle('hidden', !hasPermission || false);
});

/**
 * Hides/Shows element if the user has permission to use the passed in route
 * @param String name of route
 *
 * E.g. v-show-if-route-permission="'CommentaryBlotter'"
 */
Vue.directive('show-if-route-permission', {
  bind(el, binding) {
    if (!_.isArray(binding.value)) binding.value = [binding.value];

    const allHavePermission = _.every(binding.value, (value) => {
      let hasPermission = false;
      const route = Router.match({ name: value });
      if (route) hasPermission = Store.getters['permissions/hasPermission'](route.meta.securable, route.meta.permission);

      return hasPermission;
    });

    el.classList.toggle('hidden', !allHavePermission);
  }
});
