import Vue from 'vue';

/**
 * Detects scroll on given element
 * @param Function scroll function to call
 */
let f = {};
Vue.directive('scroll', {
  bind(el, binding) {
    f = function func(evt) {
      binding.value(evt, el);
    };

    el.addEventListener('scroll', f);
  },

  unbind(el) {
    el.removeEventListener('scroll', f);
  }
});
