import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Notifications
       *********************************************************** */

      getNotificationGroups: {
        fn() {
          return this.apiCall('GET', 'getNotificationGroups', '/admin/notification-groups');
        },
        meta: {
          name: 'getNotificationGroups',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      getNotificationGroup: {
        fn(notificationGroupId) {
          return this.apiCall('GET', 'getNotificationGroup', '/admin/notification-group', { notificationGroupId });
        },
        meta: {
          name: 'getNotificationGroup',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      saveNotificationGroup: {
        fn(data) {
          return this.apiCall('POST', 'saveNotificationGroup', '/admin/notification-group', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveNotificationGroup',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserAdmin
        }
      },

      deleteNotificationGroup: {
        fn(notificationGroupId) {
          return this.apiCall('DELETE', 'deleteNotificationGroup', '/admin/notification-group', { notificationGroupId });
        },
        meta: {
          name: 'deleteNotificationGroup',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.UserAdmin
        }
      },

      /** **********************************************************
       * Roles
       *********************************************************** */

      getRoles: {
        fn() {
          return this.apiCall('GET', 'getRoles', '/admin/roles');
        },
        meta: {
          name: 'getRoles',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      getRole: {
        fn(roleId) {
          return this.apiCall('GET', 'getRole', '/admin/role', { roleId });
        },
        meta: {
          name: 'getRole',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      saveRole: {
        fn(data) {
          return this.apiCall('POST', 'saveRole', '/admin/role', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveRole',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserAdmin
        }
      },

      deleteRole: {
        fn(roleId) {
          return this.apiCall('DELETE', 'deleteRole', '/admin/role', { roleId });
        },
        meta: {
          name: 'deleteRole',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.UserAdmin
        }
      },

      /** **********************************************************
       * Users
       *********************************************************** */

      getUser: {
        fn(userId) {
          return this.apiCall('GET', 'getUser', '/admin/user', { userId });
        },
        meta: {
          name: 'getUser',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      getUsers: {
        fn() {
          return this.apiCall('GET', 'getUsers', '/admin/users');
        },
        meta: {
          name: 'getUsers',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      saveUser: {
        fn(data) {
          return this.apiCall('POST', 'saveUser', '/admin/user', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveUser',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserAdmin
        }
      },

      deleteUser: {
        fn(userId) {
          return this.apiCall('DELETE', 'deleteUser', '/admin/user', { userId });
        },
        meta: {
          name: 'deleteUser',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.UserAdmin
        }
      },

      resetPassword: {
        fn(userId, password, reset) {
          return this.apiCall('POST', 'resetPassword', '/admin/reset-password', {
            userId,
            password,
            reset
          }, null, { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'resetPassword',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserAdmin
        }
      },

      /** **********************************************************
       * Autosave
       *********************************************************** */

      getAutosaveEntries: {
        fn(params) {
          return this.apiCall('GET', 'getAutosaveEntries', '/autosave/all', params);
        },
        meta: {
          name: 'getAutosaveEntries',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Autosave
        }
      }
    };
  }
};
