import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Draft Model
       *********************************************************** */

      getPlayerLatestModelResults: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerLatestModelResults', '/playerLatestModelResults', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerLatestModelResults',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Analytics
        }
      },

      getPlayerLatestSeasonModelResult: {
        fn(plyId) {
          const params = { plyId };
          return this.apiCall('GET', 'getPlayerLatestSeasonModelResult', '/playerLatestSeasonModelResults', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerLatestSeasonModelResult',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Analytics
        }
      },

      /** **********************************************************
       * Draft Model
       *********************************************************** */

      getAnalyticsDraftModel: {
        fn() {
          return this.apiCall('GET', 'getAnalyticsDraftModel', '/analytics/draft-model');
        },
        meta: {
          cacheable: true,
          name: 'getAnalyticsDraftModel',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        }
      },

      getPlayerDraftModelImportance: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerDraftModelImportance', '/analytics/draft-model/importance', { plyId: playerId });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerDraftModelImportance',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        }
      },

      getPlayerDraftModelImputeMatrix: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerDraftModelImputeMatrix', '/analytics/draft-model/impute-matrix', { plyId: playerId });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerDraftModelImputeMatrix',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        }
      },

      getDraftModelLatestMeta: {
        fn(params) {
          return this.apiCall('GET', 'getDraftModelLatestMeta', '/player/draft-model-meta', params);
        },
        meta: {
          cacheable: true,
          name: 'getDraftModelLatestMeta',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        }
      },

      /** **********************************************************
       * ARoles
       *********************************************************** */

      // Dummy call to establish permissions for certain areas of the app
      getPlayerAnalyticalRoles: {
        fn() {
          return Promise();
        },
        meta: {
          cacheable: true,
          name: 'getPlayerAnalyticalRoles',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.aRoles
        }
      },

      getPlayerProjections: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerProjections', '/analytics/player-projections', params);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerProjections',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.aRoles
        }
      },

      /** **********************************************************
       * aLocs
       *********************************************************** */

      // Dummy call to establish permissions for certain areas of the app
      getPlayerALocs: {
        fn() {
          return Promise();
        },
        meta: {
          name: 'getPlayerALocs',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.aLocs
        }
      },

      /** **********************************************************
      * MetaClusters
      *********************************************************** */

      getLatestMetaClusterMembership: {
        fn(params) {
          return this.apiCall('GET', 'getLatestMetaClusterMembership', '/latestMetaClusterMemberships', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLatestMetaClusterMembership',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Clusters
        }
      },

      getMetaClusterComponents: {
        fn() {
          return this.apiCall('GET', 'getMetaClusterComponents', '/analytics/cluster/meta-cluster-components');
        },
        meta: {
          cacheable: true,
          name: 'getMetaClusterComponents',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Clusters
        }
      },

      /** **********************************************************
       * Performance Value
       *********************************************************** */

      // Dummy call to establish permissions for certain areas of the app
      getPerformanceValue: {
        fn() {
          return Promise();
        },
        meta: {
          cacheable: true,
          name: 'getPerformanceValue',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PerformanceValue
        }
      },

      /** **********************************************************
       * Rock Scores
       *********************************************************** */

      // Dummy call to establish permissions for certain areas of the app
      getRockScores: {
        fn() {
          return Promise();
        },
        meta: {
          cacheable: true,
          name: 'getRockScores',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Rock
        }
      }
    };
  }
};
