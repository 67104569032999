import Vue from 'vue';

/**
 * Sets CSS when clicking on the header of a sortable list
 * v-click-sort
 */
Vue.directive('click-sort', (el, binding) => {
  el.classList.add('sortable');

  let sort = binding.value;
  let order = sort.slice(0, 1);
  if (order !== '+' && order !== '-') {
    order = '';
    sort = binding.value;
  } else {
    sort = binding.value.slice(1);
  }

  const sortArray = sort.split(/[\s,.]+/).map(s => s.replace(/[ +-]+/g, ''));
  const uniqSortArray = _.uniq(_.compact(sortArray)); // uniq because modifiers are unique
  const modifierKeys = Object.keys(binding.modifiers);
  const args = binding.arg ? [binding.arg.toString()] : [];

  const modifiers = _.chain(modifierKeys)
    .concat(args)
    .compact()
    .value();
  const intersection = _.intersection(modifiers, uniqSortArray).length;
  const doesSortMatchEl = (intersection === uniqSortArray.length && intersection === modifiers.length);
  el.classList.remove('sort-asc', 'sort-desc');
  if (doesSortMatchEl) {
    if (order === '+') el.classList.add('sort-asc');
    else if (order === '-') el.classList.add('sort-desc');
  }
});
