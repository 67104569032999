import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Main/Dashboard
  {
    path: '/',
    // Not using Vue Router convention (name) b/c only needed for router.js to attach routes
    routeName: 'MainRoot',
    component: () => import('@/views/MainRoot.vue'),
    children: [
      {
        path: '',
        name: 'CommonDashboard',
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UNRESTRICTED
        },
        component: () => import('@/views/common/CommonDashboard.vue')
      },

      // Settings and User
      {
        path: '/settings',
        name: 'CommonSettings',
        meta: {
          pageInSearch: true,
          pageTitle: 'Settings',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UNRESTRICTED
        },
        component: () => import('@/views/common/CommonSettings.vue')
      },
      {
        path: '/user',
        name: 'CommonEditProfile',
        meta: {
          pageInSearch: true,
          pageTitle: 'Edit Profile',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserProfile
        },
        component: () => import('@/views/common/CommonEditProfile.vue')
      },
      {
        path: '/autosave-error',
        name: 'CommonAutosaveError',
        props: true,
        meta: {
          pageTitle: 'Autosave Error',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UNRESTRICTED
        },
        component: () => import('@/views/common/CommonAutosaveError.vue')
      }
    ]
  },

  // Login/Logout/Verify
  {
    path: '/login',
    name: 'CommonLogin',
    meta: {
      pageTitle: 'Login'
    },
    component: () => import('@/views/common/CommonLogin.vue')
  },
  {
    path: '/logout',
    name: 'CommonLogout',
    component: () => import('@/views/common/CommonLogout.vue')
  },
  {
    path: '/verify',
    name: 'CommonVerify',
    meta: {
      pageTitle: 'Verify'
    },
    component: () => import('@/views/common/CommonVerify.vue')
  }
];
