import Vue from 'vue';

/**
 * Verifies that two fields match
 */
Vue.directive('verify-match', function verifyMatch(el, binding) { // eslint-disable-line prefer-arrow-callback
  el.onkeyup = function onkeyup() {
    if (!_.isEmpty(binding.arg) && el.value !== binding.arg) {
      el.setCustomValidity('Does Not Match');
    } else {
      el.setCustomValidity('');
    }
  };
});
