import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Commentary Search / Summary
       *********************************************************** */
      getCommentary: {
        fn(commentaryId, params) {
          return this.apiCall('GET', 'getCommentary', `/commentary/${commentaryId}`, params, null, null, true);
        },
        meta: {
          name: 'getCommentary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllCommentary
        }
      },

      getCommentaries: {
        fn(params, page) {
          const pagedParams = this.setPage(params, page, 20);
          return this.apiCall('GET', 'getCommentaries', '/commentary', pagedParams, null, null, true);
        },
        meta: {
          name: 'getCommentaries',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllCommentary
        }
      },

      getCommentarySummary: {
        fn(dateAfter) {
          return this.apiCall('GET', 'getCommentarySummary', '/commentary/summary', { dateAfter });
        },
        meta: {
          name: 'getCommentarySummary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllSummary
        }
      },

      getCommentaryMeta: {
        fn(params) {
          const adjusted = this.mapCommentaryType(params);
          return this.apiCall('GET', 'getCommentaryMeta', '/commentary/search-meta', adjusted);
        },
        meta: {
          name: 'getCommentaryMeta',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllCommentary
        }
      },

      saveCommentary: {
        fn(commentary) {
          return this.apiCall('POST', 'saveCommentary', '/commentary', null, JSON.stringify(commentary), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'saveCommentary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.AllCommentary
        }
      },

      updateCommentary: {
        fn(commentary) {
          return this.apiCall('PATCH', 'updateCommentary', `/commentary/${commentary.commentaryId}`, null, JSON.stringify(commentary), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updateCommentary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.AllCommentary
        }
      },

      deleteCommentary: {
        fn(commentaryId) {
          return this.apiCall('DELETE', 'deleteCommentary', `/commentary/${commentaryId}`, null, null, null, true);
        },
        meta: {
          name: 'deleteCommentary',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.AllCommentary
        }
      },

      /** **********************************************************
       * Events
       *********************************************************** */

      getEvents: {
        fn(params) {
          return this.apiCall('GET', 'getEvents', '/commentaryEvent', params, null, null, true);
        },
        meta: {
          name: 'getEvents',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CommentaryEvent
        }
      },

      saveEvent: {
        fn(data) {
          return this.apiCall('POST', 'saveEvent', '/commentaryEvent', null, JSON.stringify(data), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'saveEvent',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.CommentaryEvent
        }
      },

      // Dummy call to establish permissions for certain areas of the app
      deleteEvent: {
        fn() {
          return Promise();
        },
        meta: {
          cacheable: true,
          name: 'deleteEvent',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.CommentaryEvent
        }
      },

      /** **********************************************************
       * Discussions
       *********************************************************** */

      getDiscussionAttachment: {
        fn(attachmentId) {
          return this.apiCall('GET', 'getDiscussionAttachment', '/discussion/attachment', { attachmentId });
        },
        meta: {
          name: 'getDiscussionAttachment',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Discussion
        }
      },

      /** **********************************************************
       * Misc
       *********************************************************** */

      getIntelCategories: {
        fn() {
          return this.apiCall('GET', 'getIntelCategories', '/intelCategory', null, null, null, true);
        },
        meta: {
          name: 'getIntelCategories',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllCommentary
        }
      },

      getIntelSubjects: {
        fn() {
          return this.apiCall('GET', 'getIntelSubjects', '/intelSubjects', null, null, null, true);
        },
        meta: {
          name: 'getIntelSubjects',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllCommentary
        }
      },

      // Returns all users of CV that are active
      getCVUsers: {
        fn(params) {
          return this.apiCall('GET', 'getCVUsers', '/servicesAuthUserV2s', params, null, null, true);
        },
        meta: {
          name: 'getCVUsers',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllCommentary
        }
      }
    };
  }
};
