<template>
  <div id="app">
    <router-view />

    <OfflineNotification />
    <RefreshRequest />
  </div>
</template>

<script>
import OfflineNotification from '@/components/common/OfflineNotification';
import RefreshRequest from '@/components/common/RefreshRequest';
import Services from '@/mixins/Services';

export default {
  name: 'App',

  components: {
    OfflineNotification,
    RefreshRequest
  },

  mixins: [
    Services
  ],

  data() {
    return {
      subtitle: ''
    };
  },

  computed: {
    build() {
      const buildString = this.$store.getters.getBuild;
      return buildString === 'prod' ? '' : _.toUpper(buildString);
    },

    theme() {
      return this.$store.getters.getSettings.theme;
    }
  },

  watch: {
    $route() {
      this.updateSubtitle('');
    },

    theme: 'updateTheme'
  },

  async created() {
    // Clear verification token if before signature changeover date (9/30/24)
    const allVerificationTokens = this.$store.getters['security/getVerificationTokens'];
    _.each(allVerificationTokens, (token) => {
      if (token.iat <= 1727741500000) this.$store.dispatch('security/resetVerificationToken', token.localStorageKey);
    });

    // Set theme and title
    this.updateTheme();
    this.updatePageTitle();

    // Update permissions if none available - happens on print
    const authToken = this.$store.getters['security/getAuthTokenFn']();
    if (authToken && this.$store.getters['permissions/getPermissions'].length === 0) this.updatePermissions();

    // Get User details and store results
    if (authToken) {
      const userResult = await this.Services.getUserProfile();
      const user = userResult.data;
      user.fullName = user.fullName || `${user.lastName}, ${user.firstName}`;
      await this.$store.dispatch('setCurrentUser', user);
    }
  },

  mounted() {
    this.$eventHub.$on('update-subtitle', this.updateSubtitle);
  },

  beforeDestroy() {
    this.$eventHub.$off('update-subtitle');
  },

  methods: {
    updatePageTitle() {
      const pageTitle = this.$route.meta.pageTitle || 'Court Vision';
      const subtitle = this.subtitle || '';
      window.document.title = _.chain([this.build, subtitle, pageTitle]).compact().join(' - ').value();
    },

    updatePermissions() {
      this.Services.getCurrentUserPermissions()
        .then((result) => {
          this.$store.dispatch('permissions/setPermissions', result.data);
        });
    },

    updateSubtitle(newSubtitle) {
      this.subtitle = newSubtitle;
      this.updatePageTitle();
    },

    updateTheme() {
      document.body.classList.remove('base', 'dark', 'fiesta', 'sochan');
      document.body.classList.add(this.theme);
    }
  }
};
</script>

<style>
  /* 3rd party CSS */
  @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
  @import '../node_modules/croppie/croppie.css';
  @import '../node_modules/video.js/dist/video-js.min.css';

  /* Fonts */
  @import './assets/fonts/cv3/css/icons.css';

  /* Global CV3 CSS */
  @import './styles/new/colors.css';

  /* CV2 CSS */
  @import './styles/general.css';
  @import './styles/controls.css';
  @import './styles/tables.css';
  @import './styles/modules.css';
  @import './styles/print.css';

  /* Specific CV3 CSS */
  @import './styles/new/animations.css';
  @import './styles/new/calendar.css';
  @import './styles/new/forms.css';
  @import './styles/new/general.css';
  @import './styles/new/generic-components.css';
  @import './styles/new/headers.css';
  @import './styles/new/icons.css';
  @import './styles/new/lists.css';
  @import './styles/new/loaders.css';
  @import './styles/new/misc.css';
  @import './styles/new/modal.css';
  @import './styles/new/modules.css';
  @import './styles/new/print.css';
  @import './styles/new/nav.css';
  @import './styles/new/search.css';
  @import './styles/new/tables.css';
  @import './styles/new/text.css';
  @import './styles/new/salary-book.css';
  @import './styles/new/utility.css';
  @import './styles/new/visualizations.css';
</style>
