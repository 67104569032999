/**
 * DraftValue filter
 * Returns any draft values of 61/99 as 'Undrafted'
 *
 * @param {integer/string} input
 *
 * Example: {{ draftModel.spursValue | draftValue }}
 */

import Vue from 'vue';

export default function draftValueFunction(input) {
  if (!input) return '';

  let newText = input;
  newText = _.replace(newText, 99, 'Undrafted');
  newText = _.replace(newText, 61, 'Undrafted');
  newText = _.replace(newText, '99', 'Undrafted');
  newText = _.replace(newText, '61', 'Undrafted');

  if (newText.indexOf('–') >= 0) newText = _.replace(newText, 'Undrafted', 'Und');

  return newText;
}

Vue.filter('draftValue', draftValueFunction);
