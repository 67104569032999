import PERMISSIONS from '@/store/PermissionConstants';

export default [
  {
    path: '/intel/',
    name: 'IntelRoot',
    props: true,
    component: () => import('@/views/intel/IntelRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'intel',
      securable: PERMISSIONS.IntelCommentary
    },

    // Children Routes
    children: [
      {
        path: '/intel/events',
        name: 'IntelEvents',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Intel Events',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerIntel
        },
        component: () => import('@/views/intel/IntelEvents.vue')
      },
      {
        path: '/intel/event/:id',
        name: 'IntelEvent',
        props: true,
        meta: {
          pageTitle: 'Intel Event',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerIntel
        },
        component: () => import('@/views/intel/IntelEvent.vue')
      },
      {
        path: '/intel/my-reports',
        name: 'IntelMyReports',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'My Reports',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerIntel
        },
        component: () => import('@/views/intel/IntelMyReports.vue')
      },
      {
        path: '/intel/reports',
        name: 'IntelReports',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Intel Reports',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.IntelCommentary
        },
        component: () => import('@/views/intel/IntelReports.vue')
      },
      {
        path: '/intel/report/:id?',
        name: 'IntelReport',
        props: true,
        meta: {
          pageTitle: 'Intel Report',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.IntelCommentary
        },
        component: () => import('@/views/intel/IntelReport.vue')
      },

      /**
       *  Report Forms
       */
      {
        path: '/intel/edit/:id?',
        name: 'IntelPlayerSingle',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Edit Intel',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerIntel
        },
        component: () => import('@/views/intel/edit/IntelPlayerSingle.vue')
      },
      {
        path: '/intel/batch',
        name: 'IntelBatchRoot',
        props: true,
        meta: {
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerIntel
        },
        component: () => import('@/views/intel/edit/IntelBatchRoot.vue'),

        // Children Routes
        // All require an id
        children: [
          {
            path: '/intel/batch/summary/:id?',
            name: 'IntelBatchSummary',
            props: true,
            meta: {
              pageInSearch: true,
              pageTitle: 'Intel - Summary',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerIntel
            },
            component: () => import('@/views/intel/edit/IntelBatchSummary.vue')
          },
          {
            path: '/intel/batch/players/:id',
            name: 'IntelBatchPlayers',
            props: true,
            meta: {
              pageTitle: 'Intel - Players',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerIntel
            },
            component: () => import('@/views/intel/edit/IntelBatchPlayers.vue')
          },
          {
            path: '/intel/batch/review/:id',
            name: 'IntelBatchReview',
            props: true,
            meta: {
              disallowAbort: true,
              pageTitle: 'Intel - Review',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerIntel
            },
            component: () => import('@/views/intel/edit/IntelBatchReview.vue')
          }
        ]
      },
      {
        path: '/intel/event',
        name: 'IntelEventRoot',
        props: true,
        meta: {
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerIntel
        },
        component: () => import('@/views/intel/edit/IntelEventRoot.vue'),

        // Children Routes
        // All require an id
        children: [
          {
            path: '/intel/event/summary/:id',
            name: 'IntelEventSummary',
            props: true,
            meta: {
              disallowAbort: true,
              pageInSearch: true,
              pageTitle: 'Event - Summary',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerIntel
            },
            component: () => import('@/views/intel/edit/IntelEventSummary.vue')
          },
          {
            path: '/intel/event/players/:id',
            name: 'IntelEventPlayers',
            props: true,
            meta: {
              disallowAbort: true,
              securable: PERMISSIONS.PlayerIntel,
              pageTitle: 'Event - Players',
              permission: PERMISSIONS.write
            },
            component: () => import('@/views/intel/edit/IntelEventPlayers.vue')
          },
          {
            path: '/intel/event/review/:id',
            name: 'IntelEventReview',
            props: true,
            meta: {
              disallowAbort: true,
              securable: PERMISSIONS.PlayerIntel,
              pageTitle: 'Event - Review',
              permission: PERMISSIONS.write
            },
            component: () => import('@/views/intel/edit/IntelEventReview.vue')
          }
        ]
      },
      {
        path: '/intel/edit-coach/:id?',
        name: 'IntelCoach',
        props: true,
        meta: {
          pageTitle: 'Edit Coach Intel',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.CoachIntel
        },
        component: () => import('@/views/intel/edit/IntelCoach.vue')
      },
      {
        path: '/intel/edit-team/:id?',
        name: 'IntelTeam',
        props: true,
        meta: {
          pageTitle: 'Edit Team Intel',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.TeamIntel
        },
        component: () => import('@/views/intel/edit/IntelTeam.vue')
      },

      // Default routes
      {
        path: '/intel/*',
        redirect: '/intel/my-reports'
      },
      {
        path: '/intel',
        redirect: '/intel/my-reports'
      }
    ]
  }
];
