// Lodash mixins
export default function lodashMixins() {
  _.mixin({
    /**
     * Memoized Throttle Function
     *
     * @param throttledFunc - function
     * @param wait - integer in ms
     * @param options {
     *   @param leading - boolean
     *   @param resolver - function
     *   @param trailing - boolean
     * }
     *
     * After setup, use function like so:
     * testMemoizedThrottleFunction({ resolverKey: 'abc' })({*});
     * First section picks which memoized function to use, second one passes the actual arguments
     * This allows for things like flush() and cancel to still work.
     * E.g: testMemoizedThrottleFunction({ resolverKey: 'abc' }).flush();
     */
    memoizeThrottle: (func, wait = 0, options = {}) => {
      const mem = _.memoize(() => _.throttle(func, wait, options), options.resolver);
      return function appliedFunc(...args) {
        return mem.apply(this, args);
      };
    },

    /**
     * Memoized Debounce Function
     *
     * @param debouncedFunc - function
     * @param wait - integer in ms
     * @param options {
     *   @param leading - boolean
     *   @param resolver - function
     *   @param trailing - boolean
     * }
     *
     * After setup, use function like so:
     * testMemoizedDebounceFunction({ resolverKey: 'abc' })({*});
     * First section picks which memoized function to use, second one passes the actual arguments
     * This allows for things like flush() and cancel to still work.
     * E.g: testMemoizedDebounceFunction({ resolverKey: 'abc' }).flush();
     */
    memoizeDebounce: (func, wait = 0, options = {}) => {
      const mem = _.memoize(() => _.debounce(func, wait, options), options.resolver);
      return function appliedFunc(...args) {
        return mem.apply(this, args);
      };
    }
  });
}
