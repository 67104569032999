/**
 * Commentary Name filter
 * Returns a appropriate name for the commentary item
 *
 * @param {object} commentary item
 * @returns {string} Name
 */

import Vue from 'vue';

export default function commentaryNameFunction(input) {
  if (!input) return '';

  switch (input.personnelType) {
    case 'team':
      return input.team ? input.team.fullName : '';
    case 'agent':
      return input.agent ? input.agent.fullName : '';
    case 'coach':
      return input.coach ? input.coach.fullName : '';
    case 'player':
    default:
      return input.player ? input.player.fullName : '';
  }
}

Vue.filter('commentaryName', commentaryNameFunction);
