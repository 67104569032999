import Vue from 'vue';

/**
 * Sets CSS height/width
 */
Vue.directive('height', {
  bind(el, binding) {
    el.style.height = +binding.value;
  }
});
Vue.directive('width', {
  bind(el, binding) {
    el.style.width = +binding.value;
  }
});

/**
 * Sets the maximum height of an element to the body
 * Sets overflow
 * @param Object - options:
 *  offset - reduces max height by given number
 */
Vue.directive('full-height', {
  bind(el, binding) {
    const offset = binding.value.offset ? binding.value.offset : 0;

    el.style.overflowY = 'auto';
    el.style.maxHeight = `${window.innerHeight - offset}px`;
  }
});
