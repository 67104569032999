/**
 * DynamicFilter filter
 * Runs a given filter on the given input
 * Used when a single row could have different filters required
 *
 * @param {input} input
 * @param {filter} filter to run
 * @returns {object} Returns object modified by filter given
 */

import Vue from 'vue';

export default function dynamicFilterFunction(input, filter) {
  // If filter exists
  if (filter) {
    // If filter is a string
    if (typeof filter === 'string') {
      // If filter has : param, strip it off (only supports one)
      if (filter.indexOf(':') !== -1) {
        const propArray = filter.split(':');
        const name = propArray[0].trim();
        const prop = propArray[1].trim();

        return Vue.options.filters[name](input, prop);
      }

      return Vue.options.filters[filter](input);
    }

    // Otherwise assume object filter
    const filterName = filter.name;
    return Vue.options.filters[filterName](input, filter.params);
  }

  // If no filter, return unmodified input
  return input;
}

Vue.filter('dynamicFilter', dynamicFilterFunction);
