/**
 * Handles all Search data and events
 */

import dayjs from 'dayjs';
import LocalForage from 'localforage';
import SearchWorker from '@/workers/Search.worker.js';
import CONSTANTS from '@/store/Constants';

const config = {
  searchDataStorageLocation: 'san-antonio-spurs-search-data',
  searchLastUpdatedLocation: 'san-antonio-spurs-search-last-updated'
};

export default {
  namespaced: true,

  // Actual State stored
  state: {
    data: [],
    lastUpdated: '',
    loadedTime: ''
  },

  mutations: {
    initialise(state) {
      // Initialise lastUpdated from localStorage (sync)
      state.lastUpdated = localStorage.getItem(config.searchLastUpdatedLocation);

      const commit = this.commit; // used inside worker fn below where "this" isn't available
      const getters = this.getters; // used inside worker fn below where "this" isn't available

      // Initialise Search Data from LocalForage keys if available (async)
      LocalForage.getItem(config.searchDataStorageLocation)
        .then((localForageResult) => {
          if (localForageResult) {
            // Temporary measure for transition to object based localForage
            // TODO - still needed? - yes until all scouts are on CV3
            if (_.isString(localForageResult)) {
              localForageResult = JSON.parse(localForageResult);
              commit('search/updateData', localForageResult);
            } else {
              state.data = Object.freeze(localForageResult);
            }

            commit('search/updateLoadedTime');

            // Set default player id to random spurs player - defaults to constants player if something doesn't work
            let defaultPlayerId = CONSTANTS.defaults.playerId;
            const currentSpursPlayers = getters['search/getFilteredData'](null, 'player', CONSTANTS.defaults.spursId);
            if (currentSpursPlayers.length) {
              const randomPlayer = currentSpursPlayers[_.random(0, currentSpursPlayers.length)];
              if (randomPlayer) defaultPlayerId = randomPlayer.id;
            }

            if (!getters.getNavState.playerId) commit('setNavState', { playerId: defaultPlayerId });
          }
        });
    },

    // Adds a new entry
    addEntry(state, newEntryObj) {
      const newEntry = newEntryObj.data;
      if (!newEntry.typeLabel && newEntryObj.type) newEntry.typeLabel = _.toUpper(newEntryObj.type);
      if (!newEntry.objectType && newEntryObj.type) newEntry.objectType = newEntryObj.type;
      if (!newEntry.fullName && (newEntry.lastname || newEntry.firstname)) newEntry.fullName = `${newEntry.lastname}, ${newEntry.firstname}`;
      newEntry.isSearchable = true;

      // Copy state.data since the original is frozen
      const newData = _.cloneDeep(state.data);
      newData.push(newEntry);
      this.commit('search/updateData', newData);
    },

    // Removes a specific entry
    removeEntry(state, id) {
      const clonedData = _.cloneDeep(state.data);
      const ind = _.findIndex(clonedData, d => d.id === id);
      const culledData = clonedData.splice(ind, 1);

      this.commit('search/updateData', culledData);
    },

    // Updates a specific entry
    updateEntry(state, updatedEntry) {
      const entryInd = _.findIndex(state.data, d => d.id === updatedEntry.id);
      if (entryInd >= 0) {
        // Copy state.data since the original is frozen
        const originalData = _.cloneDeep(state.data);
        originalData[entryInd] = _.assign(originalData[entryInd], updatedEntry);
        originalData.isSearchable = true;

        this.commit('search/updateData', originalData);
      }
    },

    // Search data
    updateData(state, newData) {
      // Worker to augment data
      const searchWorker = new SearchWorker();
      searchWorker.onmessage = (e) => {
        state.data = Object.freeze(e.data); // Freezing prevents Vue making it reactive which kills performance
        LocalForage.setItem(config.searchDataStorageLocation, e.data);
        searchWorker.terminate();
        this.commit('search/updateLastUpdated');
      };
      searchWorker.postMessage(newData);
    },

    // Last Updated timestamp
    updateLastUpdated(state) {
      const date = dayjs().toISOString();
      localStorage.setItem(config.searchLastUpdatedLocation, date);
      state.lastUpdated = date;
    },

    // Loaded Time timestamp
    updateLoadedTime(state) {
      state.loadedTime = dayjs().toISOString();
    }
  },

  actions: {
    addEntry({ commit }, newEntry) {
      commit('addEntry', newEntry);
    },

    removeEntry({ commit }, id) {
      commit('removeEntry', id);
    },

    setData({ commit }, data) {
      if (data) commit('updateData', data);
    },

    updateEntry({ commit }, updatedEntry) {
      commit('updateEntry', updatedEntry);
    }
  },

  getters: {
    getData: state => state.data,
    getLoadedTime: state => state.loadedTime,
    getLastUpdated: state => state.lastUpdated,
    getSpecificDataById: state => id => _.find(state.data, item => item.id === id),

    /** Filter search data
     * @param typeFilter - object with .type and .objectType properties
     * @param subgroup - limit your results to a type ex. 'player' - e.g. { type: 'team' }
     * @param teamId - limit your results to a specific teamId
    */
    getFilteredData: state => (typeFilter, subgroup, teamId) => {
      return _.filter(state.data, (item) => {
        if (!item.isSearchable) return false;

        if (teamId) {
          if (item.teamId !== teamId) return false;
        }

        if (subgroup) {
          // Check for object subgroup type match
          if (subgroup === 'high-school') {
            // If type high-school check for team and level 9
            if (item.objectType !== 'team' || item.levelId !== 9) {
              return false;
            }
          } else if (subgroup === 'college') {
            // If type college check for team and levels 4-8
            if (item.objectType !== 'team' || !_.includes([4, 5, 6, 7, 8], item.levelId)) {
              return false;
            }
          } else if (subgroup !== item.objectType) {
            return false;
          }
        }

        if (typeFilter) {
          if (typeFilter.type === 'personnel') {
            // If type personnel check for any personnel type
            if (item.objectType === 'coach'
              || item.objectType === 'scout'
              || item.objectType === 'agent'
              || item.objectType === 'contact'
            ) {
              return true;
            }
          }

          // Check for object type match
          if (typeFilter.type !== item.objectType) {
            return false;
          }

          // Check for level match
          if (typeFilter.levels && !_.includes(typeFilter.levels, item.levelId)) {
            return false;
          }
        }

        return true;
      });
    },

    isStale: (state) => {
      // Data is too old or non-existent
      if (dayjs(state.lastUpdated).isBefore(dayjs().subtract(6, 'hours'))) return true;
      if (!state.lastUpdated) return true;

      // Data is empty even though we have lastUpdated there probably not loaded from LocalForage yet
      if (state.lastUpdated && state.data.length === 0) return false;

      // Data is good
      return false;
    }
  }
};
