/**
 * No leading zero filter
 * Returns given decimal number with the leading zero removed
 * e.g. 0.4 -> .4
 *
 * @param {string} percentage
 * @returns {string} string
 *
 * Example: {{ stat.percentThreePoints | noLeadingZero }}
 */

import Vue from 'vue';

export default function noLeadingZeroFunction(input) {
  if (input === undefined) return '';

  while (input.length > 1 && input.charAt(0) === '0') {
    input = input.slice(1);
  }

  return input;
}

Vue.filter('noLeadingZero', noLeadingZeroFunction);
