import CONSTANTS from '@/store/Constants';
import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Coach Wrapper
  {
    path: '/coach/focus-list',
    name: 'CoachFocusList',
    props: false,
    meta: {
      disallowAbort: true,
      pageInSearch: true,
      pageTitle: 'Focus List',
      permission: PERMISSIONS.read,
      primary: 'coach',
      securable: PERMISSIONS.CoachIntel
    },
    component: () => import('@/views/coach/CoachFocusList.vue')
  },
  {
    path: '/coach/',
    name: 'CoachRoot',
    props: true,
    component: () => import('@/views/coach/CoachRoot.vue'),
    meta: {
      defaultId: CONSTANTS.defaults.coachId,
      idName: 'coachId',
      permission: PERMISSIONS.read,
      securable: PERMISSIONS.Coach
    },

    // Children Routes
    children: [
      {
        path: '/coach/analytic-assessment/:coachId?',
        name: 'CoachAnalyticAssessment',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Analytic Assessment',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Coach
        },
        component: () => import('@/views/coach/CoachAnalyticAssessment.vue')
      },
      {
        path: '/coach/bio/:coachId?',
        name: 'CoachBio',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Bio',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Coach
        },
        component: () => import('@/views/coach/CoachBio.vue')
      },
      {
        path: '/coach/commentary/:coachId?',
        name: 'CoachCommentary',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Commentary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CoachIntel
        },
        component: () => import('@/views/coach/CoachCommentary.vue')
      },
      {
        path: '/coach/summary/:coachId?',
        name: 'CoachSummary',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Summary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CoachIntel
        },
        component: () => import('@/views/coach/CoachSummary.vue')
      },

      // Edit
      {
        path: '/coach/analytic-assessment-edit/:coachId',
        name: 'CoachEditAnalyticAssessment',
        props: true,
        meta: {
          pageTitle: 'Edit Analytic Notes',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Coach
        },
        component: () => import('@/views/coach/edit/CoachEditAnalyticAssessment.vue')
      },
      {
        path: '/coach/bio-edit/:coachId?',
        name: 'CoachEditBio',
        props: true,
        meta: {
          allowBlankId: true,
          defaultId: CONSTANTS.defaults.coachId,
          pageTitle: 'Edit Bio',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Coach
        },
        component: () => import('@/views/coach/edit/CoachEditBio.vue')
      },
      {
        path: '/coach/headshot-edit/:coachId?',
        name: 'CoachEditHeadshot',
        props: true,
        meta: {
          pageTitle: 'Edit Coach Headshot',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Coach
        },
        component: () => import('@/views/coach/edit/CoachEditHeadshot.vue')
      },
      {
        path: '/coach/summary-edit/:coachId?',
        name: 'CoachEditSummary',
        props: true,
        meta: {
          pageTitle: 'Edit Summary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.CoachIntel
        },
        component: () => import('@/views/coach/edit/CoachEditSummary.vue')
      },

      // Default routes
      {
        path: '/coach/*',
        redirect: '/coach/focus-list'
      },
      {
        path: '/coach',
        redirect: '/coach/focus-list'
      }
    ]
  }
];
