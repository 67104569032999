/**
 * Nba Percentage filter
 * Returns given number as a 3 digit percentage
 * e.g. 0.4 -> 0.400
 *
 * @param {float} percentage
 * @returns {string} Percent string
 *
 * Example: {{ stat.percentThreePoints | nbaPercentage }}
 */

import Vue from 'vue';

export default function nbaPercentageFunction(input, decimalPlaces) {
  if (input === undefined) return '';
  if (!_.isNumber(input)) return input;

  if (decimalPlaces === undefined) {
    decimalPlaces = 3;
  }

  if (input < 1) {
    const formatted = d3.format(',.3f')(input);
    return formatted.slice(1);
  }

  return d3.format(',.2f')(input);
}

Vue.filter('nbaPercentage', nbaPercentageFunction);
