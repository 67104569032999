/**
 * Intel Relationship filter
 * Returns formatted relationship
 *
 * @param {object} relationship
 * @returns {string} FormattedRelationship String
 */

import Vue from 'vue';

export default function intelRelationshipFunction(intel) {
  if (!intel || _.isEmpty(intel)) return intel;

  if (intel.relationship && intel.relationshipCategory) {
    return `${intel.relationshipCategory} - ${intel.relationship}`;
  }
  if (intel.category && intel.subcategory) {
    return `${intel.category} - ${intel.subcategory}`;
  }
  if (intel.category && intel.relationship) {
    return `${intel.category} - ${intel.relationship}`;
  }
  if (intel.relationship) {
    return intel.relationship;
  }
  if (intel.subcategory) {
    return intel.subcategory;
  }

  return '';
}

Vue.filter('intelRelationship', intelRelationshipFunction);
