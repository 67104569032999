import PERMISSIONS from '@/store/PermissionConstants';

export default {
  data() {
    return {
      /** **********************************************************
       * Cap Projections
       *********************************************************** */

      getSalaryCapProjections: {
        fn() {
          return this.apiCall('GET', 'getSalaryCapProjections', '/contracts/salary-cap-projections');
        },
        meta: {
          cacheable: true,
          name: 'getSalaryCapProjections',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        }
      },

      /** **********************************************************
       * Minimum Salary Scale
       *********************************************************** */

      getMinSalaryScale: {
        fn() {
          return this.apiCall('GET', 'getMinSalaryScale', '/contracts/min-salary-scale');
        },
        meta: {
          cacheable: true,
          name: 'getMinSalaryScale',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        }
      },

      /** **********************************************************
       * Rookie Scale
       *********************************************************** */

      getRookieScale: {
        fn() {
          return this.apiCall('GET', 'getRookieScale', '/contracts/rookie-scale');
        },
        meta: {
          cacheable: true,
          name: 'getRookieScale',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        }
      },

      /** **********************************************************
       * Salary Book
       *********************************************************** */

      getSalaryBookLedgers: {
        fn(params) {
          return this.apiCall('GET', 'getSalaryBookLedgers', '/salaryBookLedgers', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getSalaryBookLedgers',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        }
      },

      /** **********************************************************
       * System Values
       *********************************************************** */

      getSystemValues: {
        fn() {
          return this.apiCall('GET', 'getSystemValues', '/contracts/system-values');
        },
        meta: {
          cacheable: true,
          name: 'getSystemValues',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        }
      }
    };
  }
};
