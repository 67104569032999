import PERMISSIONS from '@/store/PermissionConstants';

export default [
  {
    path: '/contracts/',
    name: 'ContractsRoot',
    props: true,
    component: () => import('@/views/contracts/ContractsRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'contracts',
      securable: PERMISSIONS.AllContract
    },

    // Children Routes
    children: [
      {
        path: '/contracts/cap-projections',
        name: 'ContractsCapProjections',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Cap Projections',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsCapProjections.vue')
      },
      {
        path: '/contracts/min-salary-scale',
        name: 'ContractsMinSalaryScale',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Minimum Salary Scale',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsMinSalaryScale.vue')
      },
      {
        path: '/contracts/rookie-scale',
        name: 'ContractsRookieScale',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Rookie Scale',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsRookieScale.vue')
      },
      {
        path: '/contracts/roster-tool',
        name: 'ContractsRosterTool',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Roster Tool',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsRosterTool.vue')
      },
      {
        path: '/contracts/salary-book/:teamId?',
        name: 'ContractsSalaryBook',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Salary Book',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsSalaryBook.vue')
      },
      {
        path: '/contracts/salary-structure-calculator',
        name: 'ContractsSalaryStructureCalculator',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Salary Structure Calculator',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsSalaryStructureCalculator.vue')
      },
      {
        path: '/contracts/system-values',
        name: 'ContractsSystemValues',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'System Values',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllContract
        },
        component: () => import('@/views/contracts/ContractsSystemValues.vue')
      },

      // Default routes
      {
        path: '/contracts/*',
        redirect: '/contracts/salary-book'
      },
      {
        path: '/contracts',
        redirect: '/contracts/salary-book'
      }
    ]
  }
];
