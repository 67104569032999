import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Lookup Global List
       *********************************************************** */

      getLookupGlobalList: {
        fn() {
          return this.apiCall('GET', 'getLookupGlobalList', '/lookup/global-list');
        },
        meta: {
          name: 'getLookupGlobalList',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllGlobal
        }
      },

      /** **********************************************************
       * Lookup Player Stat Years
       *********************************************************** */

      getLookupPlayerStatYears: {
        fn(playerId) {
          return this.apiCall('GET', 'getLookupPlayerStatYears', '/lookup/player/stat-years', { playerId });
        },
        meta: {
          cacheable: true,
          name: 'getLookupPlayerStatYears',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Lookup Person Team Seasons
       *********************************************************** */

      getLookupPersonTeamSeasons: {
        fn(playerId) {
          return this.apiCall('GET', 'getLookupPersonTeamSeasons', '/lookup/player/person-team-seasons', { playerId });
        },
        meta: {
          cacheable: true,
          name: 'getLookupPersonTeamSeasons',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupLeague
        }
      },

      /** **********************************************************
       * Lookup League Seasons
       *********************************************************** */

      getLookupLeagues: {
        fn() {
          return this.apiCall('GET', 'getLookupLeagues', '/lookup/leagues');
        },
        meta: {
          cacheable: true,
          name: 'getLookupLeagues',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupLeague
        }
      },

      getLookupLeagueSeason: {
        fn(leagueId, season) {
          return this.apiCall('GET', 'getLookupLeagueSeason', '/lookup/league/league-season', { leagueId, season });
        },
        meta: {
          cacheable: true,
          name: 'getLookupLeagueSeason',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupLeague
        }
      },

      getLookupLeagueSeasons: {
        fn(leagueId) {
          return this.apiCall('GET', 'getLookupLeagueSeasons', '/lookup/league/league-seasons', { leagueId });
        },
        meta: {
          cacheable: true,
          name: 'getLookupLeagueSeasons',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupLeague
        }
      },

      /** **********************************************************
       * Lookup Team Seasons
       *********************************************************** */

      getLookupTeamLeagueSeasons: {
        fn(teamId) {
          return this.apiCall('GET', 'getLookupTeamLeagueSeasons', '/lookup/team/league-seasons', { teamId });
        },
        meta: {
          cacheable: true,
          name: 'getLookupTeamLeagueSeasons',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupLeague
        }
      },

      getTeamSeasonPriorityByTmId: {
        fn(teamId) {
          return this.apiCall('GET', 'getTeamSeasonPriorityByTmId', '/lookup/team/team-season-priority', { teamId });
        },
        meta: {
          cacheable: true,
          name: 'getTeamSeasonPriorityByTmId',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupLeague
        }
      },

      /** **********************************************************
       * Medium Lookups
       *********************************************************** */

      getLookupReportMediums: {
        fn() {
          return this.apiCall('GET', 'getLookupReportMediums', '/lookup/report/mediums');
        },
        meta: {
          cacheable: true,
          name: 'getLookupReportMediums',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupReportMedium
        }
      },

      /** **********************************************************
       * Misc Lookups
       *********************************************************** */

      getLookupDiscussionTypes: {
        fn() {
          return this.apiCall('GET', 'getLookupDiscussionTypes', '/discussionTypes', null, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLookupDiscussionTypes',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupDiscussionType
        }
      },

      getLookupTeamLevels: {
        fn() {
          return this.apiCall('GET', 'getLookupTeamLevels', '/lookup/team-levels');
        },
        meta: {
          cacheable: true,
          name: 'getLookupTeamLevels',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Team
        }
      },

      getLookupGameTypes: {
        fn() {
          const params = {
            embeds: ['gameGroups']
          };
          return this.apiCall('GET', 'getLookupGameTypes', '/gameTypes', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLookupGameTypes',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getLookupGameGroups: {
        fn() {
          return this.apiCall('GET', 'getLookupGameGroups', '/gameGroups', null, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLookupGameGroups',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getLookupZoneGroups: {
        fn() {
          const params = {
            embeds: ['shotZones']
          };
          return this.apiCall('GET', 'getLookupZoneGroups', '/zoneGroups', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLookupZoneGroups',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Shot
        }
      },

      getLookupSplits: {
        fn() {
          return this.apiCall('GET', 'getLookupSplits', '/splits', null, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLookupSplits',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getLookupShotFilterSets: {
        fn() {
          return this.apiCall('GET', 'getLookupShotFilterSets', '/shotFilterSets', null, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getLookupShotFilterSets',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      }
    };
  }
};
