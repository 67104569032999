import { register } from 'register-service-worker';

// Nightly runs on production, but we don't want to do any of the caching stuff
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_api.indexOf('nightly') < 0) {
  register(`${process.env.BASE_URL}Cache.worker.js`, {
    // Keep for release
    error(error) {
      console.error('Error during service worker registration:', error);
    },

    updated(registration) {
      console.info(`New version of CV available. You are currently using ${process.env.VUE_APP_version}. To use the new version, refresh the Service Worker by refreshing the page or clicking the Reload Site Button`);

      window.dispatchEvent(new CustomEvent('refresh-site', {
        detail: {
          sw: registration.waiting
        }
      }));
    }
  });
}
