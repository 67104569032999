import Api from '@/mixins/Api';
import AdminApi from '@/mixins/api/AdminApi';
import AnalyticsApi from '@/mixins/api/AnalyticsApi';
import AttachmentsApi from '@/mixins/api/AttachmentsApi';
import CoachApi from '@/mixins/api/CoachApi';
import CommentaryApi from '@/mixins/api/CommentaryApi';
import CommonApi from '@/mixins/api/CommonApi';
import ContractApi from '@/mixins/api/ContractApi';
import GamesApi from '@/mixins/api/GamesApi';
import IntelApi from '@/mixins/api/IntelApi';
import ListsApi from '@/mixins/api/ListsApi';
import LookupApi from '@/mixins/api/LookupApi';
import MedicalApi from '@/mixins/api/MedicalApi';
import PermissionsApi from '@/mixins/api/PermissionsApi';
import PersonnelApi from '@/mixins/api/PersonnelApi';
import PlayerApi from '@/mixins/api/PlayerApi';
import ReportsApi from '@/mixins/api/ReportsApi';
import SecurityApi from '@/mixins/api/SecurityApi';
import TeamApi from '@/mixins/api/TeamApi';

export default {
  mixins: [Api],

  /**
   Attach each Api call as a function of this.Services (this being the main Vue instance)
   Bind (this) to each call so that the call is able to access the Api mixin which contains
   the actual get/post/delete http functions.
  */
  created() {
    const apis = [AdminApi, AnalyticsApi, AttachmentsApi, CoachApi, CommentaryApi, CommonApi, ContractApi, GamesApi, IntelApi, ListsApi, LookupApi, MedicalApi, PermissionsApi, PersonnelApi, PlayerApi, ReportsApi, SecurityApi, TeamApi];
    this.Services = {
      meta: {}
    };

    _.each(apis, (api) => {
      _.each(api.data(), (method) => {
        this.Services[method.meta.name] = method.fn.bind(this);
        this.Services.meta[method.meta.name] = method.meta;
      });
    });
  },

  methods: {
    getServiceMeta(name) {
      return this.Services.meta[name];
    },

    /**
     * Utility to parse sort property and direction from a
     * header string typically in the format of "property:dir"
     * This returns and object with field and direction values
     * that contain the extracted text values.
     *
     * @param sortString - input from sort header
     * @returns {{field:string, direction:string}}
     */
    parseSortObject(sortString) {
      if (!sortString) { return { field: '', direction: '' }; }

      const parts = sortString.split(':');
      const sort = {};
      sort.field = parts[0];
      sort.direction = parts[1].trim().toLowerCase();

      return sort;
    }
  }
};
