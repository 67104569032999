/**
 * This just imports the various plugins needed throughout the app
 * It exports a dummy fn so that the setup is called
 */

import dayjs from 'dayjs';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import dayOfYearPlugin from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(advancedFormatPlugin);
dayjs.extend(dayOfYearPlugin);
dayjs.extend(isBetween);
dayjs.extend(relativeTimePlugin);

// Have to extend in this order
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

export default function dummyFn() {}
