import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Personnel Wrapper
  {
    path: '/schedules/',
    name: 'SchedulesRoot',
    props: true,
    component: () => import('@/views/schedules/SchedulesRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'schedules',
      securable: PERMISSIONS.Game
    },

    // Children Routes
    children: [
      {
        path: '/schedules/NBA/:defaultDate?',
        name: 'SchedulesNBA',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'NBA',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        },
        component: () => import('@/views/schedules/SchedulesNBA.vue')
      },
      {
        path: '/schedules/GLeague/:defaultDate?',
        name: 'SchedulesGLeague',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'GLeague',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        },
        component: () => import('@/views/schedules/SchedulesGLeague.vue')
      },
      {
        path: '/schedules/NCAA/:defaultDate?',
        name: 'SchedulesNCAA',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'NCAA',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        },
        component: () => import('@/views/schedules/SchedulesNCAA.vue')
      },
      {
        path: '/schedules/game/:gameId',
        name: 'GameDetail',
        props: true,
        meta: {
          pageTitle: 'Game',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        },
        component: () => import('@/views/schedules/GameDetail.vue')
      },

      // Default routes
      {
        path: '/schedules/*',
        redirect: '/schedules/NBA'
      },
      {
        path: '/schedules',
        redirect: '/schedules/NBA'
      }
    ]
  }
];
