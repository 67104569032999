/**
 * Phone Number filter
 * Returns given a number formatted as a phone number
 * Only formats it if a string/number of 10 digits is sent - this keeps international numbers intact
 * e.g. 2105461234 => (210) 546-1234
 *
 * @param {number, string}
 * @returns {string} Phone Number
 */

import Vue from 'vue';

export default function phoneNumberFunction(input) {
  if (!_.isNumber(input) && !_.isString(input)) return input;
  let formatted = '';

  if (_.isNumber(input)) {
    formatted = input.toString();
  }

  formatted = _.replace(input, /([-._() ])/g, '');

  // Only format American numbers this way
  // - Determined by length of 10 digits, or a + including country code
  if (formatted.length === 10 && _.indexOf(formatted, '+') < 0) {
    const areaCode = formatted.slice(0, 3);
    const firstSection = formatted.slice(3, 6);
    const lastSection = formatted.slice(6);

    return `(${areaCode}) ${firstSection}-${lastSection}`;
  }

  return input.toString();
}

Vue.filter('phoneNumber', phoneNumberFunction);
