// This file groups permissions into collections for use by both Routes and API definitions

// Commentary by Type Collections
const AttachmentCommentary = [
  'AgentAttachment',
  'CoachAttachment',
  'PlayerAttachment',
  'TeamAttachment'
];

const IntelCommentary = [
  'CoachIntel',
  'PlayerIntel',
  'TeamIntel'
];

const JournalCommentary = [
  'AgentJournal',
  'CoachJournal',
  'PlayerJournal'
];

const MediaCommentary = [
  'PlayerMedia',
  'TeamMedia'
];

// Commentary by Personnel Collections
const AgentCommentary = [
  'AgentAttachment',
  'AgentJournal',
  'Discussion'
];

const CoachCommentary = [
  'CoachAttachment',
  'CoachIntel',
  'CoachJournal',
  'Discussion'
];

const PlayerCommentary = [
  'CommentaryEvent',
  'Discussion',
  'PlayerAttachment',
  'PlayerBlotter',
  'PlayerHundred',
  'PlayerIntel',
  'PlayerJournal',
  'PlayerMedia',
  'PlayerScouting'
];

const TeamCommentary = [
  'Discussion',
  'TeamAttachment',
  'TeamIntel',
  'TeamMedia'
];

// All Collections
const AllCommentary = [
  'AgentAttachment',
  'AgentJournal',
  'CoachAttachment',
  'CoachIntel',
  'CoachJournal',
  'CommentaryEvent',
  'Discussion',
  'PlayerAttachment',
  'PlayerBlotter',
  'PlayerHundred',
  'PlayerIntel',
  'PlayerJournal',
  'PlayerMedia',
  'PlayerScouting',
  'ScoutingEvent',
  'TeamAttachment',
  'TeamIntel',
  'TeamMedia'
];

const AllContract = [
  'SalaryBook'
];

const AllMedical = [
  'PlayerAthleticTest',
  'PlayerInjury'
];

const AllPersonnel = [
  'Agent',
  'Coach',
  'Contact',
  'Scout'
];

const AllReports = [
  'CommentaryScoutSummary',
  'Document',
  'FaxbackReport',
  'FourFactorReport',
  'ListRolesReport',
  'PostgameReport',
  'SeenByScoutReport'
];

const AllScouting = [
  'PlayerScouting',
  'ScoutingEvent'
];

const AllSummary = [
  'CoachIntel',
  'PlayerAthleticTest',
  'PlayerBlotter',
  'PlayerInjury',
  'PlayerIntel',
  'PlayerScouting',
  'TeamIntel'
];

const AllGlobal = [
  'Agent',
  'Coach',
  'Contact',
  'Player',
  'Scout',
  'Team'
];

export default {

  // Singular Permission references
  read: 'READ',
  write: 'WRITE',
  delete: 'DELETE',

  accessLevels: {
    all: 'ALL',
    int: 'INT',
    limited: 'LIMITED',
    med: 'MED',
    own: 'OWN',
    rawo: 'RAWO',
    spur: 'SPUR'
  },

  UNRESTRICTED: null,

  ADMIN_ONLY: 'UserAdmin',

  Agent: 'Agent',
  AgentAttachment: 'AgentAttachment',
  AgentJournal: 'AgentJournal',
  Analytics: 'Analytics',
  AnalyticsGroup: 'AnalyticsGroup',
  aLocs: 'aLocs',
  aRoles: 'aRoles',
  Clusters: 'Clusters',
  DraftModel: 'DraftModel',
  PerformanceValue: 'PerformanceValue',
  Rock: 'Rock',
  Coach: 'Coach',
  CoachAttachment: 'CoachAttachment',
  CoachHistory: 'CoachHistory',
  CoachIntel: 'CoachIntel',
  CoachJournal: 'CoachJournal',
  CommentaryEvent: 'CommentaryEvent',
  CommentaryScoutSummary: 'CommentaryScoutSummary',
  Contact: 'Contact',
  Cyclops: 'Cyclops',
  Discussion: 'Discussion',
  Document: 'Document',
  FaxbackReport: 'FaxbackReport',
  FourFactorReport: 'FourFactorReport',
  Game: 'Game',
  List: 'List',
  ListCategory: 'ListCategory',
  ListRolesReport: 'ListRolesReport',
  LookupDiscussionMedium: 'LookupDiscussionMedium',
  LookupDiscussionType: 'LookupDiscussionType',
  LookupDraftRange: 'LookupDraftRange',
  LookupEventType: 'LookupEventType',
  LookupIntelCategory: 'LookupIntelCategory',
  LookupIntelMedium: 'LookupIntelMedium',
  LookupIntelRelationship: 'LookupIntelRelationship',
  LookupIntelSubject: 'LookupIntelSubject',
  LookupJournalMedium: 'LookupJournalMedium',
  LookupLeague: 'LookupLeague',
  LookupLocs: 'LookupLocs',
  LookupMediaMedium: 'LookupMediaMedium',
  LookupMedium: 'LookupMedium',
  LookupPlayerDesignation: 'LookupPlayerDesignation',
  LookupRelationship: 'LookupRelationship',
  LookupReportMedium: 'LookupReportMedium',
  MergePlayer: 'MergePlayer',
  Notifications: 'Notifications',
  Player: 'Player',
  PlayerAnalyticAssessment: 'PlayerAnalyticAssessment',
  PlayerAthleticTest: 'PlayerAthleticTest',
  PlayerAttachment: 'PlayerAttachment',
  PlayerBlotter: 'PlayerBlotter',
  PlayerDna: 'PlayerDna',
  PlayerHundred: 'PlayerHundred',
  PlayerInjury: 'PlayerInjury',
  PlayerIntel: 'PlayerIntel',
  PlayerJournal: 'PlayerJournal',
  PlayerMedia: 'PlayerMedia',
  PlayerSalary: 'PlayerSalary',
  PlayerScouting: 'PlayerScouting',
  PlayerShot: 'PlayerShot',
  PlayerStats: 'PlayerStats',
  PlayerSummary: 'PlayerSummary',
  PostgameReport: 'PostgameReport',
  Roster: 'Roster',
  SalaryBook: 'SalaryBook',
  Scout: 'Scout',
  ScoutingEvent: 'ScoutingEvent',
  SeenByScoutReport: 'SeenByScoutReport',
  Team: 'Team',
  TeamAsset: 'TeamAsset',
  TeamAttachment: 'TeamAttachment',
  TeamIntel: 'TeamIntel',
  TeamMedia: 'TeamMedia',
  TeamStanding: 'TeamStanding',
  UserAdmin: 'UserAdmin',
  UserProfile: 'UserProfile',

  AgentCommentary,
  AttachmentCommentary,
  CoachCommentary,
  IntelCommentary,
  JournalCommentary,
  MediaCommentary,
  PlayerCommentary,
  TeamCommentary,

  AllCommentary,
  AllContract,
  AllGlobal,
  AllMedical,
  AllPersonnel,
  AllReports,
  AllScouting,
  AllSummary
};
