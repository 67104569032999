import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {

      /** **********************************************************
       * Settings
       *********************************************************** */

      getServerStats: {
        fn() {
          return this.apiCall('GET', 'getServerStats', '/server/stats');
        },
        meta: {
          cacheable: true,
          name: 'getServerStats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UNRESTRICTED
        }
      },

      /** **********************************************************
       * Autosave
       *********************************************************** */

      getAutosave: {
        fn(params) {
          return this.apiCall('GET', 'getAutosave', '/autosave', params);
        },
        meta: {
          name: 'getAutosave',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Autosave
        }
      },

      postAutosave: {
        fn(data) {
          return this.apiCall('POST', 'postAutosave', '/autosave', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'postAutosave',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Autosave
        }
      },

      /** **********************************************************
       * User Related
       *********************************************************** */

      getUserProfile: {
        fn() {
          return this.apiCall('GET', 'getUserProfile', '/profile');
        },
        meta: {
          name: 'getUserProfile',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserProfile
        }
      },

      saveUserProfile: {
        fn(data) {
          return this.apiCall('POST', 'saveUserProfile', '/profile', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveUserProfile',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserProfile
        }
      },

      getNotificationGroupFrequencies: {
        fn(userId) {
          return this.apiCall('GET', 'getNotificationGroupFrequencies', '/profile/notification-group-frequencies', { userId });
        },
        meta: {
          name: 'getNotificationGroupFrequencies',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserProfile
        }
      },

      saveNotificationGroupFrequencies: {
        fn(data) {
          return this.apiCall('POST', 'saveNotificationGroupFrequencies', '/profile/notification-group-frequency', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveNotificationGroupFrequencies',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserProfile
        }
      },

      updatePassword: {
        fn(newPassword, oldPassword) {
          return this.apiCall('POST', 'updatePassword', '/profile/update-password', {
            newPassword,
            oldPassword
          }, null, { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'updatePassword',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.UserProfile
        }
      }
    };
  }
};
