/*
Notes on Store:
- All authentication tokens and verification tokens are stored on localStorage as well as Vuex. This is to make sure it is persisted across reloads.
- Ditto Search Data
*/

import Vue from 'vue';
import Vuex from 'vuex';
import LocalForage from 'localforage';
import Permissions from '@/store/Permissions';
import Search from '@/store/Search';
import Security from '@/store/Security';
import CONSTANTS from '@/store/Constants';

Vue.use(Vuex);

const config = {
  currentUserStorage: 'san-antonio-spurs-user',
  settingsStorage: 'san-antonio-spurs-settings'
};

/**
 * Main Store
 */
const store = new Vuex.Store({

  modules: {
    permissions: Permissions,
    search: Search,
    security: Security
  },

  // Actual State stored
  state: {
    alert: null,
    build: '',
    currentSeason: 2020, // Doesn't matter the value here since it is updated by the API calls on first load
    currentUser: null,
    nav: {
      name: 'CommonDashboard',
      params: {},
      query: {},

      playerId: '',
      teamId: CONSTANTS.defaults.teamId,
      agentId: CONSTANTS.defaults.agentId,
      coachId: CONSTANTS.defaults.coachId,
      contactId: CONSTANTS.defaults.contactId,
      scoutId: CONSTANTS.defaults.scoutId
    },
    nbaSeasonDates: [],
    settings: {
      sochanModalShown: false,
      recentCommentaryDays: 7,
      theme: 'base'
    },
    servicesDeferred: [],
    servicesInProgress: []
  },

  // Mutations that directly affect state - SYNCHRONOUS
  mutations: {

    /**
     * Initialise Store from localStorage keys if available
     * All calls in this function are finished BEFORE Vue is initialized
     * Settings are in LocalForage
     *
     * Anything in localStorage is synchronous
     * Anything in LocalForage is asynchronous (a promise)
     */
    initialiseStore(state) {
      this.commit('permissions/initialise'); // found in store/Permissions.js
      this.commit('search/initialise'); // found in store/Search.js
      this.commit('security/initialise'); // found in store/Security.js

      // Since LocalForage is async, use async/await to make sure this is loaded synchronously
      async function getFromLF() {
        const settingsResult = await LocalForage.getItem(config.settingsStorage);
        state.settings = _.assign(state.settings, settingsResult);
        const currentUserResult = await LocalForage.getItem(config.currentUserStorage);
        state.currentUser = _.assign(state.currentUser, currentUserResult);
      }

      getFromLF();
    },

    setNavState(state, nav) {
      _.assign(state.nav, nav);
    },

    setPageSettingState(state, setting) {
      const newObj = {};
      if (setting.page) {
        _.set(newObj, `${setting.page}.${setting.setting}`, setting.value);
        state.settings = _.assign({}, state.settings, newObj);
      } else {
        _.set(newObj, setting.setting, setting.value);
        state.settings = _.assign({}, state.settings, newObj);
      }

      LocalForage.setItem(config.settingsStorage, state.settings);
    },

    updateServicesInProgress(state, service) {
      state.servicesInProgress.push(service);
    },

    removeServiceInProgress(state, service) {
      const index = _.findIndex(state.servicesInProgress, sp => sp.name === service);
      if (index >= 0) state.servicesInProgress.splice(index, 1);
    },

    addServiceDeferred(state, signature) {
      state.servicesDeferred.push(signature);
    },

    removeServiceDeferred(state, signature) {
      const index = _.findIndex(state.servicesDeferred, s => _.isEqual(s[2], signature[2]) && _.isEqual(s[3], signature[3]));
      if (index >= 0) state.servicesDeferred.splice(index, 1);
    },

    removeAllServiceDeferred(state) {
      state.servicesDeferred = [];
    },

    updateAlert(state, alert) {
      state.alert = alert;
    },

    /**
     * Remove alert
     * If removeSecurityAlert passed, remove alerts even if state.alert.securityAlert = true
     */
    removeAlert(state, removeSecurityAlert) {
      if (state.alert && state.alert.securityAlert) {
        if (removeSecurityAlert) state.alert = null;
        // else keep login alert
      } else state.alert = null;
    },

    // Removes a specific alert if it is being shown
    removeSpecificAlert(state, removeText) {
      if (state?.alert?.text === removeText) state.alert = null;
    },

    updateBuild(state, build) {
      state.build = build;
    },

    updateCurrentSeason(state, season) {
      state.currentSeason = season;
    },

    updateNbaSeasonDates(state, seasonDates) {
      state.nbaSeasonDates = seasonDates;
    },

    updateCurrentUser(state, user) {
      state.currentUser = user;
      if (user) LocalForage.setItem(config.currentUserStorage, state.currentUser);
      else LocalForage.removeItem(config.currentUserStorage);
    }
  },

  // Various actions that affect state, should call mutations and not set state directly - ASYNC
  actions: {
    addServiceInProgress({ commit }, service) {
      commit('updateServicesInProgress', service);
    },

    removeServiceInProgress({ commit }, service) {
      commit('removeServiceInProgress', service);
    },

    addServiceDeferred({ commit }, signature) {
      commit('addServiceDeferred', signature);
    },

    removeServiceDeferred({ commit }, signature) {
      commit('removeServiceDeferred', signature);
    },

    removeAllServiceDeferred({ commit }) {
      commit('removeAllServiceDeferred');
    },

    /**
     * updatePageSetting updates a given setting with a given value for a given page
     *
     * @param Object e.g. { page: 'CommentaryAttachments', setting: 'viewType', value: 'grid' }
     */
    updatePageSetting({ commit }, settingObj) {
      if (settingObj) commit('setPageSettingState', settingObj);
    },

    addAlert({ commit }, alert) {
      commit('updateAlert', alert);
    },

    removeAlert({ commit }, removeSecurityAlert) {
      commit('removeAlert', removeSecurityAlert);
    },

    setBuild({ commit }, build) {
      if (build) commit('updateBuild', build);
    },

    setCurrentSeason({ commit }, season) {
      commit('updateCurrentSeason', season);
    },

    setNbaSeasonDates({ commit }, seasonDates) {
      commit('updateNbaSeasonDates', seasonDates);
    },

    setCurrentUser({ commit }, user) {
      if (!user) commit('updateCurrentUser', null);
      else commit('updateCurrentUser', user);
    }
  },

  // Computed or helper Getters
  getters: {
    getAlert: state => state.alert,
    getBuild: state => state.build,
    getCurrentSeason: state => state.currentSeason,
    getCurrentUser: state => state.currentUser,
    getNbaSeasonDates: state => state.nbaSeasonDates,
    getNavState: state => state.nav,
    getSettings: state => state.settings,

    getServicesDeferred: state => state.servicesDeferred,
    getServiceInProgress: state => (service) => {
      return _.some(state.servicesInProgress, sp => sp.name === service);
    },
    getServicesInProgress: state => state.servicesInProgress,
    getLoading: state => !_.isEmpty(state.servicesInProgress)
  }
});

/* Export final version */
export default store;
