import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Lists - General
       *********************************************************** */

      getLists: {
        fn() {
          return this.apiCall('GET', 'getLists', '/lists');
        },
        meta: {
          name: 'getLists',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      getListsV2: {
        fn(params) {
          return this.apiCall('GET', 'getListsV2', '/preflists', params, null, null, true);
        },
        meta: {
          name: 'getListsV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      /** **********************************************************
       * Lists - Categories
       *********************************************************** */
      getListCategories: {
        fn() {
          return this.apiCall('GET', 'getListCategories', '/list/categories');
        },
        meta: {
          name: 'getListCategories',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.ListCategory
        }
      },

      saveListCategory: {
        fn(data) {
          return this.apiCall('POST', 'saveListCategory', '/list/category', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveListCategory',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.ListCategory
        }
      },

      deleteListCategory: {
        fn(categoryId) {
          return this.apiCall('DELETE', 'deleteList', '/list/category', { id: categoryId });
        },
        meta: {
          name: 'deleteListCategory',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.ListCategory
        }
      },

      /** **********************************************************
       * Lists - Single List
       *********************************************************** */

      getList: {
        fn(listId, includePlayers) {
          const includePlayersBool = includePlayers === true;
          return this.apiCall('GET', 'getList', '/list', { listId, includePlayers: includePlayersBool })
            .then((result) => {
              result.data = this.mapListColors(result.data, false); // Format colors into hex
              return result;
            });
        },
        meta: {
          name: 'getList',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      getListV2: {
        fn(params) {
          return this.apiCall('GET', 'getListV2', '/preflist', params, null, null, true)
            .then((result) => {
              if (result.data) result.data[0] = this.mapListColors(result.data[0], false); // Format colors into hex
              return result;
            });
        },
        meta: {
          name: 'getListV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      getListPlayers: {
        fn(params) {
          return this.apiCall('GET', 'getListPlayers', '/preflistPlayers', params, null, null, true)
            .then((result) => {
              result.data = this.mapListColors(result.data, false); // Format colors into hex
              return result;
            });
        },
        meta: {
          name: 'getListPlayers',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      saveList: {
        fn(data) {
          const adjusted = this.mapListColors(data, true); // Format colors into int
          return this.apiCall('POST', 'saveList', '/list', null, JSON.stringify(adjusted), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveList',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.List
        }
      },

      saveListV2: {
        fn(data) {
          const adjusted = this.mapListColors(data, true); // Format colors into int
          return this.apiCall('POST', 'saveListV2', '/preflist', null, JSON.stringify(adjusted), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'saveListV2',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.List
        }
      },

      updateListV2: {
        fn(listId, data) {
          const adjusted = this.mapListColors(data, true); // Format colors into int
          return this.apiCall('PATCH', 'updateListV2', `/preflists/${listId}`, null, JSON.stringify(adjusted), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updateListV2',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.List
        }
      },

      deleteList: {
        fn(listId) {
          return this.apiCall('DELETE', 'deleteList', '/list', { listId });
        },
        meta: {
          name: 'deleteList',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.List
        }
      },

      /** **********************************************************
       * List - Augmenting
       *********************************************************** */

      getListDraftModels: {
        fn(listId) {
          return this.apiCall('GET', 'getListDraftModels', '/player/draft-model-list', { listId });
        },
        meta: {
          name: 'getListDraftModels',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        }
      },

      /** **********************************************************
       * List - Logs
       *********************************************************** */

      getListOpenLogs: {
        fn() {
          return this.apiCall('GET', 'getListOpenLogs', '/list/open-logs');
        },
        meta: {
          name: 'getListOpenLogs',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      getListOpenLogsV2: {
        fn(params) {
          return this.apiCall('GET', 'getListOpenLogsV2', '/preflistOpenLogs', params, null, null, true);
        },
        meta: {
          name: 'getListOpenLogsV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      saveListOpenLog: {
        fn(data) {
          return this.apiCall('POST', 'saveListOpenLog', '/list/open-log', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveListOpenLog',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.List
        }
      }
    };
  }
};
