<!-- TODO? -->
<!-- BNL checked using the favicon as well as navigator.onLine (checkUsingFavicon in bnl-angular.js) -->

<template>
  <transition name="slide-fade">
    <div v-if="show" class="offline-wrapper">
      <div class="status-indicator" :class="{ 'offline': offline }"></div>
      <p v-if="offline" class="offline-text text-danger">Currently Offline</p>
      <p v-else class="offline-text">Currently Online</p>

      <p class="subtext">Requests to the server will fail</p>
      <p class="subtext">but reports will be stored locally</p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OfflineNotification',

  data() {
    return {
      offline: !window.navigator.onLine,
      show: !window.navigator.onLine
    };
  },

  mounted() {
    window.addEventListener('offline', this.goOffline);
    window.addEventListener('online', this.goOnline);
  },

  beforeDestroy() {
    window.removeEventListener('offline', this.goOffline);
    window.removeEventListener('online', this.goOnline);
  },

  methods: {
    goOffline() {
      this.offline = true;
      this.show = true;
    },

    goOnline() {
      this.offline = false;
      setTimeout(() => {
        this.show = false;
      }, 1000);
    }
  }
};
</script>

<style scoped>
  .offline-wrapper {
    align-items: center;
    background-color: var(--card-background);
    border-radius: 4px 4px 0 0;
    bottom: 0;
    box-shadow: 0 0 3px var(--box-shadow);
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    position: fixed;
    right: 1.5em;
    z-index: 2000;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  .slide-fade-enter-to,
  .slide-fade-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .offline-text {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .subtext {
    font-size: 12px;
    margin: 0;
  }

  .status-indicator {
    animation: none;
    background-color: var(--flag-green) !important;
    border-radius: 100%;
    height: 10px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 10px;
  }

  .status-indicator.offline {
    animation: fadeInOut infinite 2s;
    background-color: var(--error) !important;
  }

  @keyframes fadeInOut {
    from {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    to {
      opacity: 0.5;
    }
  }

  @media (max-width: 991px) {
    .offline-wrapper {
      right: calc(50vw - 155px)
    }
  }
</style>
