import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Player Intel CRUD
       *********************************************************** */
      savePlayerIntel: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerIntel', '/player/intel', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerIntel',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerIntel
        }
      },

      deletePlayerIntel: {
        fn(intelId) {
          return this.apiCall('DELETE', 'deletePlayerIntel', '/player/intel', { intelId });
        },
        meta: {
          name: 'deletePlayerIntel',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.PlayerIntel
        }
      },

      /** **********************************************************
       * Intel Relationships
       *********************************************************** */

      getIntelRelationships: {
        fn() {
          return this.apiCall('GET', 'getCommentary', '/relationshipTypes', null, null, null, true);
        },
        meta: {
          name: 'getIntelRelationships',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.LookupIntelRelationship
        }
      }
    };
  }
};
