import CONSTANTS from '@/store/Constants';
import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Personnel Wrapper
  {
    path: '/personnel/',
    name: 'PersonnelRoot',
    props: true,
    component: () => import('@/views/personnel/PersonnelRoot.vue'),
    meta: {
      defaultId: CONSTANTS.defaults.scoutId,
      idName: 'scoutId',
      permission: PERMISSIONS.read,
      primary: 'personnel',
      securable: PERMISSIONS.AllPersonnel
    },

    // Children Routes
    children: [
      // Agents
      {
        path: '/personnel/agent/:agentId?',
        name: 'PersonnelAgent',
        props: true,
        meta: {
          defaultId: CONSTANTS.defaults.agentId,
          idName: 'agentId',
          pageTitle: 'Agent',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Agent
        },
        component: () => import('@/views/personnel/PersonnelAgent.vue')
      },
      {
        path: '/personnel/agent-edit/:agentId?',
        name: 'PersonnelAgentEdit',
        props: true,
        meta: {
          allowBlankId: true,
          defaultId: CONSTANTS.defaults.agentId,
          idName: 'agentId',
          pageTitle: 'Agent',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Agent
        },
        component: () => import('@/views/personnel/edit/PersonnelAgentEdit.vue')
      },
      {
        path: '/personnel/agent-edit-headshot/:agentId?',
        name: 'PersonnelAgentEditHeadshot',
        props: true,
        meta: {
          pageTitle: 'Edit Agent Headshot',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Agent
        },
        component: () => import('@/views/personnel/edit/PersonnelAgentEditHeadshot.vue')
      },

      // Contacts
      {
        path: '/personnel/contact/:contactId?',
        name: 'PersonnelContact',
        props: true,
        meta: {
          defaultId: CONSTANTS.defaults.contactId,
          idName: 'contactId',
          pageTitle: 'Contact',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Contact
        },
        component: () => import('@/views/personnel/PersonnelContact.vue')
      },
      {
        path: '/personnel/contact-edit/:contactId?',
        name: 'PersonnelContactEdit',
        props: true,
        meta: {
          allowBlankId: true,
          defaultId: CONSTANTS.defaults.contactId,
          disallowAbort: true,
          idName: 'contactId',
          pageTitle: 'Contact',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Contact
        },
        component: () => import('@/views/personnel/edit/PersonnelContactEdit.vue')
      },
      {
        path: '/personnel/contact-edit-headshot/:contactId?',
        name: 'PersonnelContactEditHeadshot',
        props: true,
        meta: {
          pageTitle: 'Edit Contact Headshot',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Contact
        },
        component: () => import('@/views/personnel/edit/PersonnelContactEditHeadshot.vue')
      },

      // Scouts
      {
        path: '/personnel/scout/:scoutId?',
        name: 'PersonnelScout',
        props: true,
        meta: {
          defaultId: CONSTANTS.defaults.scoutId,
          idName: 'scoutId',
          pageTitle: 'Scout',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Scout
        },
        component: () => import('@/views/personnel/PersonnelScout.vue')
      },
      {
        path: '/personnel/scout-edit/:scoutId?',
        name: 'PersonnelScoutEdit',
        props: true,
        meta: {
          allowBlankId: true,
          defaultId: CONSTANTS.defaults.scoutId,
          idName: 'scoutId',
          pageTitle: 'Edit Scout',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Scout
        },
        component: () => import('@/views/personnel/edit/PersonnelScoutEdit.vue')
      },
      {
        path: '/personnel/scout-edit-headshot/:scoutId?',
        name: 'PersonnelScoutEditHeadshot',
        props: true,
        meta: {
          pageTitle: 'Edit Scout Headshot',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Scout
        },
        component: () => import('@/views/personnel/edit/PersonnelScoutEditHeadshot.vue')
      },

      // Default routes
      {
        path: '/personnel/*',
        redirect: '/personnel/scout'
      },
      {
        path: '/personnel',
        redirect: '/personnel/scout'
      }
    ]
  }
];
