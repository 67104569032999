import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Load Reports
       *********************************************************** */

      getDailyLoads: {
        fn(params) {
          return this.apiCall('GET', 'getDailyLoads', '/medical/athletic/player-day-load', params);
        },
        meta: {
          name: 'getDailyLoads',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        }
      },

      getLeagueLoadMeanStd: {
        fn(startDate, endDate) {
          return this.apiCall('GET', 'getLeagueLoadMeanStd', '/medical/athletic/load-mean-std', { startDate, endDate });
        },
        meta: {
          name: 'getLeagueLoadMeanStd',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        }
      },

      getDesignationDayLoad: {
        fn(params) {
          return this.apiCall('GET', 'getDesignationDayLoad', '/medical/athletic/designation-day-load', params);
        },
        meta: {
          name: 'getDesignationDayLoad',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        }
      },

      getInjuryAnalysis: {
        fn(params) {
          return this.apiCall('GET', 'getInjuryAnalysis', '/medical/athletic/spurs-injury-analysis', params);
        },
        meta: {
          name: 'getInjuryAnalysis',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        }
      },

      /** **********************************************************
       * Smartabase
       *********************************************************** */

      getSmartabaseOAuth: {
        fn(email, url) {
          return this.apiCall('GET', 'getSmartabaseOAuth', '/smartabase', { email, url });
        },
        meta: {
          name: 'getSmartabaseOAuth',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        }
      }
    };
  }
};
