/**
 * Designation to PosId filter
 * Returns given position integer
 * e.g. Big || B -> 3
 *
 * @param {string} designation
 * @returns {integer} Position Id
 */

import Vue from 'vue';
import CONSTANTS from '@/store/Constants';

export default function designationToPosIdFunction(designation) {
  if (!_.isString(designation)) return designation;

  let index = -1;
  // designation.length is checking for the first letter, not for an array
  if (designation.length === 1) index = _.findIndex(CONSTANTS.designationsList, d => d[0] === designation);
  else index = _.findIndex(CONSTANTS.designationsList, d => d === designation);

  if (index >= 0) return index + 1;
  return 0;
}

Vue.filter('designationToPosId', designationToPosIdFunction);
