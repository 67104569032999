import PERMISSIONS from '@/store/PermissionConstants';

export default {
  data() {
    return {
      /** **********************************************************
       * Player Crud
       *********************************************************** */

      getPlayer: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayer', '/player', { playerId });
        },
        meta: {
          name: 'getPlayer',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      savePlayer: {
        fn(data) {
          return this.apiCall('POST', 'savePlayer', '/player', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayer',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        }
      },

      getPlayerV2: {
        fn(playerId, params) {
          return this.apiCall('GET', 'getPlayerV2', `/players/${playerId}`, params, null, null, true);
        },
        meta: {
          name: 'getPlayerV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      getPlayersV2: {
        fn(params) {
          return this.apiCall('GET', 'getPlayersV2', '/players', params, null, null, true);
        },
        meta: {
          name: 'getPlayersV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      updatePlayer: {
        fn(player) {
          if (player.sourcePlayerId) {
            return this.apiCall('PATCH', 'updatePlayer', `/spursPlayers/${player.sourcePlayerId}`, null, JSON.stringify(player), { 'Content-Type': 'application/json' }, true);
          }

          return this.apiCall('POST', 'updatePlayer', '/spursPlayers', null, JSON.stringify(player), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updatePlayer',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        }
      },

      /** **********************************************************
       * Player Analytic Assessments
       *********************************************************** */

      getPlayerAnalyticAssessment: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerAnalyticAssessment', '/analyticalAssessments', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerAnalyticAssessment',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      getAnalyticalAssessmentByPersonSeasonId: {
        fn(personSeasonId) {
          return this.apiCall('GET', 'getAnalyticalAssessmentByPersonSeasonId', '/player/analytical-assessment-personseason', { personSeasonId });
        },
        meta: {
          cacheable: true,
          name: 'getAnalyticalAssessmentByPersonSeasonId',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      getAnalyticalAssessmentByListId: {
        fn(listId) {
          return this.apiCall('GET', 'getAnalyticalAssessmentByListId', '/player/analytical-assessment-list', { listId });
        },
        meta: {
          name: 'getAnalyticalAssessmentByListId',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Player Bio
       *********************************************************** */

      getPlayerBio: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerBio', '/player/bio', { playerId });
        },
        meta: {
          name: 'getPlayerBio',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      getPlayerInternationals: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerInternationals', '/player/internationals', { playerId });
        },
        meta: {
          name: 'getPlayerInternationals',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      getPlayerContacts: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerContacts', '/playerContacts', params, null, null, true);
        },
        meta: {
          name: 'getPlayerContacts',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Contact
        }
      },

      updatePlayerContacts: {
        fn(updatedContacts, playerId) {
          return this.apiCall('PATCH', 'updatePlayerContacts', `/player/${playerId}`, null, JSON.stringify(updatedContacts), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updatePlayerContacts',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Contact
        }
      },

      savePlayerContact: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerContact', '/player/contact', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerContact',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Contact
        }
      },

      deletePlayerContact: {
        fn(data) {
          return this.apiCall('DELETE', 'deletePlayerContact', '/player/contact', data, null, { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'deletePlayerContact',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.Contact
        }
      },

      getPlayerPersonal: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerPersonal', '/player/bio', { playerId });
        },
        meta: {
          name: 'getPlayerPersonal',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      savePlayerPersonal: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerPersonal', '/player/bio', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerPersonal',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        }
      },

      /** **********************************************************
       * Player DNA
       *********************************************************** */

      getPlayerDnas: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerDnas', '/player/dnas', { playerId });
        },
        meta: {
          name: 'getPlayerDnas',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerDna
        }
      },

      getPlayerDnasV2: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerDnasV2', '/playerDnas', params, null, null, true);
        },
        meta: {
          name: 'getPlayerDnasV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerDna
        }
      },

      savePlayerDnas: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerDnas', '/player/dnas', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerDnas',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerDna
        }
      },

      /** **********************************************************
       * Player Draft Models
       *********************************************************** */

      getPlayerDraftModel: {
        fn(options) {
          return this.apiCall('GET', 'getPlayerDraftModel', '/player/draft-model/search', { plyId: options.playerId, scouting: options.scouting });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerDraftModel',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        }
      },

      /** **********************************************************
       * Player Game Logs
       *********************************************************** */

      getPlayerGameLogs: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerGameLogs', '/vGamePlayerLog', params, null, null, true);
        },
        meta: {
          name: 'getPlayerGameLogs',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Player Games Played - Player/Game-Chart-Log
       *********************************************************** */

      getGameChartLog: {
        fn(personSeasonId) {
          return this.apiCall('GET', 'getGameChartLog', '/player/game-chart-log', { personSeasonId });
        },
        meta: {
          cacheable: true,
          name: 'getGameChartLog',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Player Playing History
       *********************************************************** */

      getPersonTeamSeasons: {
        fn(params) {
          return this.apiCall('GET', 'getPersonTeamSeasons', '/personTeamSeasons', params, null, null, true);
        },
        meta: {
          name: 'getPersonTeamSeasons',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      savePlayerPersonTeamSeasons: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerPersonTeamSeasons', '/player/person-team-seasons', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerPersonTeamSeasons',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Player
        }
      },

      deletePersonTeamSeasonDates: {
        fn(data) {
          return this.apiCall('DELETE', 'deletePersonTeamSeasonDates', '/player/person-team-season-dates', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'deletePersonTeamSeasonDates',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.Player
        }
      },

      /** **********************************************************
       * Player Measurements
       *********************************************************** */

      getPlayerMeasurements: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerMeasurements', '/player/measurements', { plyId: playerId });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerMeasurements',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      getPlayerMeasurementsV2: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerMeasurementsV2', '/playerMeasurements', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerMeasurementsV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      },

      /** **********************************************************
       * Player PersonSeasons
       *********************************************************** */

      getPersonSeasonDetails: {
        fn(playerId, personSeasonId) {
          if (personSeasonId) return this.apiCall('GET', 'getPersonSeasonDetails', `/personSeasonDetails/${personSeasonId}`, null, null, null, true);
          return this.apiCall('GET', 'getPersonSeasonDetails', '/personSeasonDetails', { plyId: playerId }, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPersonSeasonDetails',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      getPersonSeasonDefaults: {
        fn(playerId) {
          return this.apiCall('GET', 'getPersonSeasonDefaults', `/personSeasonDefaults/${playerId}`, { embeds: ['personSeason.personSeasonDetails'] }, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPersonSeasonDefaults',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Player Stats
       *********************************************************** */

      getPlayerStats: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerStats', '/player/stats', { playerId })
            .then((d) => {
              _.each(d.data, (x) => {
                if (x.leagueAbbr === 'NBADL') {
                  x.leagueAbbr = 'GLEAGUE';
                  x.leagueDisplayName = 'GLeague';
                  x.leagueId = 'GLEAGUE';
                  x.leagueName = 'GLeague';
                }
              });

              return d;
            });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerStats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      getPlayerStatsV2: {
        fn(personSeasonId, splitId, gameGroupId, params) {
          return this.apiCall('GET', 'getPlayerStatsV2', `/playerStats/${personSeasonId}/${splitId}/${gameGroupId}`, params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerStatsV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Player Shots
       *********************************************************** */

      getPlayerShots: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerShots', '/player/shot', params)
            .then((d) => {
              if (d.data && d.data[0].leagueId === 'NBADL') d.data[0].leagueId = 'GLEAGUE';
              return d;
            });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerShots',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        }
      },

      getPlayerFreeThrows: {
        fn(personSeasonId) {
          return this.apiCall('GET', 'getPlayerFreeThrows', '/player/shot/free-throw', { personSeasonId });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerFreeThrows',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        }
      },

      getPlayerShotsV2: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerShotsV2', '/shots', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerShotsV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        }
      },

      getPlayerShotPercentiles: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerShotPercentiles', '/shotPlayerPercentiles', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerShotPercentiles',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        }
      },

      getShotPlayerStatsMeanStd: {
        fn(params) {
          return this.apiCall('GET', 'getShotPlayerStatsMeanStd', '/shotPlayerStatsMeanStd', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getShotPlayerStatsMeanStd',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        }
      },

      /** **********************************************************
       * Player Summary - Amateur
       *********************************************************** */

      getPlayerSummary: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerSummary', '/player/summary', { playerId });
        },
        meta: {
          name: 'getPlayerSummary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      getPlayerSummaryV2: {
        fn(params, pro) {
          if (pro) return this.apiCall('GET', 'getPlayerSummaryV2', '/playerSummaryPro', params, null, null, true);
          return this.apiCall('GET', 'getPlayerSummaryV2', '/playerSummaries', params, null, null, true);
        },
        meta: {
          name: 'getPlayerSummaryV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      getPlayerSummaryReport: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerSummaryReport', '/player/summary/report', { playerId });
        },
        meta: {
          name: 'getPlayerSummaryReport',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      savePlayerSummary: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerSummary', '/player/summary', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerSummary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      /** **********************************************************
       * Player Summary - Pro
       *********************************************************** */

      // Gets PlayerProSummary that is apart of PlayerSummaryProReport
      getPlayerProSummary: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerProSummary', '/player/summary-pro', { playerId });
        },
        meta: {
          name: 'getPlayerProSummary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      getPlayerSummaryProReport: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerSummaryProReport', '/player/summary/pro-report', { playerId });
        },
        meta: {
          name: 'getPlayerSummaryProReport',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      savePlayerProSummary: {
        fn(data) {
          return this.apiCall('POST', 'savePlayerProSummary', '/player/summary-pro', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'savePlayerProSummary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerSummary
        }
      },

      /** **********************************************************
       * Player Misc
       *********************************************************** */

      getPlayerLists: {
        fn(playerId) {
          return this.apiCall('GET', 'getPlayerLists', '/list-player-lists', { playerId });
        },
        meta: {
          name: 'getPlayerLists',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        }
      },

      getPlayerSearch: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerSearch', '/player/search', params);
        },
        meta: {
          name: 'getPlayerSearch',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Player
        }
      }
    };
  }
};
