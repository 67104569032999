import PERMISSIONS from '@/store/PermissionConstants';

export default {
  data() {
    return {
      /** **********************************************************
       * Agents
       *********************************************************** */

      getAgent: {
        fn(params, agentId) {
          return this.apiCall('GET', 'getAgent', `/agent/${agentId}`, params, null, null, true);
        },
        meta: {
          name: 'getAgent',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Agent
        }
      },

      updateAgent: {
        fn(updatedAgent) {
          if (updatedAgent.cwAgentMapSpurs.sourceAgentId) {
            return this.apiCall('PATCH', 'updateAgent', `/spursAgents/${updatedAgent.cwAgentMapSpurs.sourceAgentId}`, null, JSON.stringify(updatedAgent), { 'Content-Type': 'application/json' }, true);
          }

          return this.apiCall('POST', 'updateAgent', '/spursAgents', null, JSON.stringify(updatedAgent), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updateAgent',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Agent
        }
      },

      /** **********************************************************
       * Contacts
       *********************************************************** */

      getContact: {
        fn(params, contactId) {
          return this.apiCall('GET', 'getContact', `/contact/${contactId}`, params, null, null, true);
        },
        meta: {
          name: 'getContact',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Contact
        }
      },

      updateContact: {
        fn(updatedContact) {
          if (updatedContact.cwContactMapSpurs.sourceContactId) {
            return this.apiCall('PATCH', 'updateContact', `/spursContacts/${updatedContact.cwContactMapSpurs.sourceContactId}`, null, JSON.stringify(updatedContact), { 'Content-Type': 'application/json' }, true);
          }

          return this.apiCall('POST', 'updateContact', '/spursContacts', null, JSON.stringify(updatedContact), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updateContact',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Contact
        }
      },

      /** **********************************************************
       * Scouts
       *********************************************************** */

      getScout: {
        fn(params, scoutId) {
          return this.apiCall('GET', 'getScout', `/scout/${scoutId}`, params, null, null, true);
        },
        meta: {
          name: 'getScout',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Scout
        }
      },

      updateScout: {
        fn(updatedScout) {
          if (updatedScout.cwScoutMapSpurs.sourceScoutId) {
            return this.apiCall('PATCH', 'updateScout', `/spursScouts/${updatedScout.cwScoutMapSpurs.sourceScoutId}`, null, JSON.stringify(updatedScout), { 'Content-Type': 'application/json' }, true);
          }

          return this.apiCall('POST', 'updateScout', '/spursScouts', null, JSON.stringify(updatedScout), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updateScout',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Scout
        }
      },

      /** **********************************************************
       * Headshots
       *********************************************************** */

      uploadPersonnelHeadshot: {
        fn(personnelId, category, file, fileType) {
          if (!fileType) fileType = 'png';
          const contentType = fileType === 'svg' ? 'image/svg%2Bxml' : 'image/jpeg'; // image/svg%2Bxml = image/svg+xml

          return this.apiUpload('uploadPersonnelHeadshot', '/image/upload', {
            filename: `${personnelId}.${fileType}`,
            imageCategory: category,
            contentType
          }, file, true);
        },
        meta: {
          name: 'uploadPersonnelHeadshot',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Personnel
        }
      }
    };
  }
};
