import * as d3 from 'd3';

/* ==========================
   Tooltips
   ========================== */
function tooltipInit() {
  window.d3.tooltip = {};

  /**
   * Updated show method that takes the element bounding rectangle into account when
   * placing the tip. Also adds an arrow to the tooltip based on the gravity
   * (can be removed if 'no-arrow' is added to classes)
   *
   * @param {array} pos - [x, y]
   * @param {string} content - Content for the tooltip
   * @param {string} gravity - Direction of the tooltip if possible
   * @param {integer} dist - Distance from pos, in direction of gravity, to place the tooltip
   * @param {DOM El} element - DOM Element to base tooltip location off of (default is top-left of screen)
   * @param {object} styles - Styles to apply to the tooltip
   */
  window.d3.tooltip.display = function displayTooltip(pos, content, gravity, dist, element, styles) {
    let bounds = { left: 0, top: 0 };

    if (element) bounds = element.getBoundingClientRect();

    window.d3.tooltip.cleanup();

    window.d3.tooltip.show([pos[0] + bounds.left, pos[1] + bounds.top], content, gravity, dist, element, styles);
  };

  /**
   * Original tooltip.show function. Kept for backward compatibility.
   * pos = [left,top]
   */
  window.d3.tooltip.show = function showTooltip(pos, content, gravity, dist, parentContainer, styles) {
    // Create new tooltip div if it doesn't exist on DOM.
    const container = document.createElement('div');
    container.className = 'd3tooltip arrow-tooltip';

    let body = parentContainer;
    if (!parentContainer || parentContainer.tagName.match(/g|svg/i)) {
      // If the parent element is an SVG element, place tooltip in the <body> element.
      body = document.getElementsByTagName('body')[0];
    }

    container.style.left = 0;
    container.style.top = 0;
    container.style.opacity = 0;

    if (styles) {
      Object.entries(styles).forEach(([prop, val]) => d3.select(container).style(prop, val));
    }

    // Content can also be dom element
    if (typeof content !== 'string') {
      container.appendChild(content);
    } else {
      container.innerHTML = content;
    }

    body.appendChild(container);

    // If the parent container is an overflow <div> with scrollbars, subtract the scroll offsets.
    if (parentContainer) {
      pos[0] -= parentContainer.scrollLeft;
      pos[1] -= parentContainer.scrollTop;
    }
    window.d3.tooltip.calcTooltipPosition(pos, gravity, dist, container);
  };

  /**
   * Finds the total offsetTop of a given DOM element.
   * Looks up the entire ancestry of an element, up to the first relatively positioned element.
   */
  window.d3.tooltip.findTotalOffsetTop = function findTotalOffsetTop(Elem, initialTop) {
    let offsetTop = initialTop;

    do {
      if (!Number.isNaN(Elem.offsetTop)) {
        offsetTop += (Elem.offsetTop);
      }

      Elem = Elem.offsetParent;
    } while (Elem);
    return offsetTop;
  };

  /**
   * Finds the total offsetLeft of a given DOM element.
   * Looks up the entire ancestry of an element, up to the first relatively positioned element.
   */
  window.d3.tooltip.findTotalOffsetLeft = function findTotalOffsetLeft(Elem, initialLeft) {
    let offsetLeft = initialLeft;

    do {
      if (!Number.isNaN(Elem.offsetLeft)) {
        offsetLeft += (Elem.offsetLeft);
      }
      Elem = Elem.offsetParent;
    } while (Elem);
    return offsetLeft;
  };

  /**
   * Global utility function to render a tooltip on the DOM.
   * pos = [left,top] coordinates of where to place the tooltip, relative to the SVG chart container.
   * gravity = how to orient the tooltip
   * dist = how far away from the mouse to place tooltip
   * container = tooltip DIV
   */
  window.d3.tooltip.calcTooltipPosition = function calcTooltipPosition(pos, gravity, dist, container) {
    const height = parseInt(container.offsetHeight, 10);
    const width = parseInt(container.offsetWidth, 10);
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    const scrollTop = window.pageYOffset;
    const scrollLeft = window.pageXOffset;
    let left = 0;
    let top = 0;

    windowHeight = window.innerWidth >= document.body.scrollWidth ? windowHeight : windowHeight - 16;
    windowWidth = window.innerHeight >= document.body.scrollHeight ? windowWidth : windowWidth - 16;

    gravity = gravity || 's';
    dist = dist || 20;

    const tooltipTop = function tooltipTop(Elem) {
      return window.d3.tooltip.findTotalOffsetTop(Elem, top);
    };

    const tooltipLeft = function tooltipLeft(Elem) {
      return window.d3.tooltip.findTotalOffsetLeft(Elem, left);
    };

    const tLeft = tooltipLeft(container) || 0;
    const tTop = tooltipTop(container) || 0;
    switch (gravity) {
      case 'e':
        left = pos[0] - width - dist;
        top = pos[1] - (height / 2);
        if (tLeft < scrollLeft || left < scrollLeft) {
          left = pos[0] + dist > scrollLeft ? pos[0] + dist : (scrollLeft - tLeft) + left;
          container.className += ' no-arrow';
        }
        if (tTop < scrollTop) {
          top = (scrollTop - tTop) + top;
          container.className += ' no-arrow';
        }
        if (tTop + height > scrollTop + windowHeight) {
          top = (((scrollTop + windowHeight) - tTop) + top) - height;
          container.className += ' no-arrow';
        }
        container.className += ' east';
        break;
      case 'w':
        left = pos[0] + dist;
        top = pos[1] - (height / 2);
        if (tLeft + width > windowWidth || left + width > windowWidth) {
          left = pos[0] - width - dist;
          container.className += ' no-arrow';
        }
        if (tTop < scrollTop) {
          top = scrollTop + 5;
          container.className += ' no-arrow';
        }
        if (tTop + height > scrollTop + windowHeight) {
          top = (((scrollTop + windowHeight) - tTop) + top) - height;
          container.className += ' no-arrow';
        }
        container.className += ' west';
        break;
      case 'n':
        left = pos[0] - (width / 2) - 5;
        top = pos[1] + dist;
        if (tLeft < scrollLeft) {
          left = scrollLeft + 5;
          container.className += ' no-arrow';
        }
        if (tLeft + width > windowWidth || left + width > windowWidth) {
          left = (left - (width / 2)) + 5;
          container.className += ' no-arrow';
        }
        if (tTop + height > scrollTop + windowHeight) {
          top = (((scrollTop + windowHeight) - tTop) + top) - height;
          container.className += ' no-arrow';
        }
        container.className += ' north';
        break;
      case 's':
        left = pos[0] - (width / 2);
        top = pos[1] - height - dist;
        if (tLeft < scrollLeft) {
          left = scrollLeft + 5;
          container.className += ' no-arrow';
        }
        if (tLeft + width > windowWidth || left + width > windowWidth) {
          left = (left - (width / 2)) + 5;
          container.className += ' no-arrow';
        }
        if (scrollTop > tTop) {
          top = scrollTop;
          container.className += ' no-arrow';
        }
        container.className += ' south';
        break;
      case 'none':
      default:
        left = pos[0];
        top = pos[1] - dist;
        container.className += ' no-arrow';
        break;
    }

    container.style.left = `${left}px`;
    container.style.top = `${top}px`;
    container.style.opacity = 1;
    container.style.position = 'absolute';

    return container;
  };

  /**
   * Global utility function to remove tooltips from the DOM.
   */
  window.d3.tooltip.cleanup = function cleanup() {
    // Find the tooltips, mark them for removal by this class (so others cleanups won't find it)
    const tooltips = document.getElementsByClassName('d3tooltip');
    const purging = [];
    while (tooltips.length) {
      purging.push(tooltips[0]);
      tooltips[0].style.transitionDelay = '0 !important';
      tooltips[0].style.opacity = 0;
      tooltips[0].className = 'd3tooltip-pending-removal';
    }

    setTimeout(() => {
      while (purging.length) {
        const removeMe = purging.pop();
        removeMe.parentNode.removeChild(removeMe);
      }
    }, 500);
  };
}

/* ==========================
   Hexbins
   ========================== */
function hexbinInit() {
  window.d3.hexbin = {};

  const hexbin = {};
  let width = 1;
  let height = 1;
  let r;
  let x = d => d[0];
  let y = d => d[1];
  let dx;
  let dy;
  const hexbinAngles = d3.range(0, 2 * Math.PI, Math.PI / 3);

  window.d3.hexbin = function initHexbin() {
    return hexbin;
  };

  hexbin.bin = function hexbinBin(points) {
    const binsById = {};

    points.forEach((point, i) => {
      const py = y.call(hexbin, point, i) / dy;
      let pj = Math.round(py);
      const px = x.call(hexbin, point, i) / dx - (pj & 1 ? 0.5 : 0); // eslint-disable-line no-bitwise
      let pi = Math.round(px);
      const py1 = py - pj;

      if (Math.abs(py1) * 3 > 1) {
        const px1 = px - pi;
        const pi2 = pi + (px < pi ? -1 : 1) / 2;
        const pj2 = pj + (py < pj ? -1 : 1);
        const px2 = px - pi2;
        const py2 = py - pj2;
        if (px1 * px1 + py1 * py1 > px2 * px2 + py2 * py2) {
          pi = pi2 + (pj & 1 ? 1 : -1) / 2; // eslint-disable-line no-bitwise
          pj = pj2;
        }
      }

      const id = `${pi}-${pj}`;
      let bin = binsById[id];

      if (bin) bin.push(point);
      else {
        bin = binsById[id] = [point];
        bin.id = id;
        bin.i = pi;
        bin.j = pj;
        bin.x = (pi + (pj & 1 ? 1 / 2 : 0)) * dx; // eslint-disable-line no-bitwise
        bin.y = pj * dy;
      }
    });

    return Object.values(binsById);
  };

  function hexagon(radius) {
    let x0 = 0;
    let y0 = 0;
    return hexbinAngles.map((angle) => {
      const x1 = Math.sin(angle) * radius;
      const y1 = -Math.cos(angle) * radius;
      const deltaX = x1 - x0;
      const deltaY = y1 - y0;
      x0 = x1;
      y0 = y1;
      return [deltaX, deltaY];
    });
  }

  hexbin.x = function hexbinX(xArg) {
    if (!arguments.length) return x;
    x = xArg;
    return hexbin;
  };

  hexbin.y = function hexbinY(yArg) {
    if (!arguments.length) return y;
    y = yArg;
    return hexbin;
  };

  hexbin.hexagon = function hexbinHexagon(radius) {
    if (!arguments.length) radius = r;
    return `m${hexagon(radius).join('l')}z`;
  };

  hexbin.centers = function hexbinCenters() {
    const centers = [];
    for (let y = 0, odd = false, j = 0; y < height + r; y += dy, odd = !odd, ++j) {
      for (let x = odd ? dx / 2 : 0, i = 0; x < width + dx / 2; x += dx, ++i) {
        const center = [x, y];
        center.i = i;
        center.j = j;
        centers.push(center);
      }
    }
    return centers;
  };

  hexbin.mesh = function hexbinMesh() {
    const fragment = hexagon(r).slice(0, 4).join('l');
    return hexbin.centers().map(p => `M${p}m${fragment}`).join('');
  };

  hexbin.size = function hexbinSize(sizeArg) {
    if (!arguments.length) return [width, height];
    width = +sizeArg[0];
    height = +sizeArg[1];
    return hexbin;
  };

  hexbin.radius = function hexbinRadius(radiusArg) {
    if (!arguments.length) return r;
    r = +radiusArg;
    dx = r * 2 * Math.sin(Math.PI / 3);
    dy = r * 1.5;
    return hexbin;
  };
}

export { hexbinInit, tooltipInit };
