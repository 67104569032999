import CONSTANTS from '@/store/Constants';
import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Personnel Wrapper
  {
    path: '/medical/',
    name: 'MedicalRoot',
    props: true,
    component: () => import('@/views/medical/MedicalRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'medical',
      securable: PERMISSIONS.AllMedical
    },

    // Children Routes
    children: [
      {
        path: '/medical/all',
        name: 'MedicalAll',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Medical',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        },
        component: () => import('@/views/medical/MedicalAll.vue')
      },
      {
        path: '/medical/athletic/:id?',
        name: 'MedicalAthleticTest',
        props: true,
        meta: {
          optionTitle: 'Athletic Test',
          pageTitle: 'Athletic Test',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerAthleticTest
        },
        component: () => import('@/views/medical/MedicalAthleticTest.vue')
      },
      {
        path: '/medical/athletics',
        name: 'MedicalAthleticTests',
        props: true,
        meta: {
          optionTitle: 'Athletic Test',
          pageTitle: 'Athletic Tests',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerAthleticTest
        },
        component: () => import('@/views/medical/MedicalAthleticTests.vue')
      },
      {
        path: '/medical/availability/:teamId?',
        name: 'MedicalAvailability',
        props: true,
        meta: {
          optionTitle: 'Availability',
          pageInSearch: true,
          pageTitle: 'Availability',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllMedical
        },
        component: () => import('@/views/medical/MedicalAvailability.vue')
      },
      {
        path: '/medical/injury-report/:id?',
        name: 'MedicalInjuryReport',
        props: true,
        meta: {
          optionTitle: 'Injury Report',
          pageTitle: 'Injury',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerInjury
        },
        component: () => import('@/views/medical/MedicalInjuryReport.vue')
      },
      {
        path: '/medical/injury-reports',
        name: 'MedicalInjuryReports',
        props: true,
        meta: {
          optionTitle: 'Injury Report',
          pageInSearch: true,
          pageTitle: 'Injury',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerInjury
        },
        component: () => import('@/views/medical/MedicalInjuryReports.vue')
      },
      {
        path: '/medical/smartabase',
        name: 'MedicalSmartabase',
        props: true,
        component: () => import('@/views/medical/MedicalSmartabase.vue')
      },

      // Load Reports
      {
        path: '/medical/load-reports',
        name: 'MedicalLoadReportsRoot',
        props: true,
        meta: {
          defaultId: CONSTANTS.defaults.spursId,
          idName: 'teamId',
          pageTitle: 'Load Reports',
          permission: PERMISSIONS.read,
          secondary: 'load-reports',
          securable: PERMISSIONS.AllMedical
        },
        component: () => import('@/views/medical/load-reports/MedicalLoadReportsRoot.vue'),

        children: [
          {
            path: '/medical/load-reports/summary/:teamId?',
            name: 'MedicalLoadReportsSummary',
            props: true,
            meta: {
              disallowAbort: true,
              pageInSearch: true,
              pageTitle: 'Load Report - Summary',
              permission: PERMISSIONS.read,
              securable: PERMISSIONS.AllMedical
            },
            component: () => import('@/views/medical/load-reports/MedicalLoadReportsSummary.vue')
          },
          {
            path: '/medical/load-reports/depth-chart/:teamId',
            name: 'MedicalLoadReportsDepthChart',
            props: true,
            meta: {
              pageInSearch: true,
              pageTitle: 'Load Report - Depth Chart',
              permission: PERMISSIONS.read,
              securable: PERMISSIONS.AllMedical
            },
            component: () => import('@/views/medical/load-reports/MedicalLoadReportsDepthChart.vue')
          },
          {
            path: '/medical/load-reports/rankings/:teamId',
            name: 'MedicalLoadReportsRankings',
            props: true,
            meta: {
              pageInSearch: true,
              pageTitle: 'Load Report - Rankings',
              permission: PERMISSIONS.read,
              securable: PERMISSIONS.AllMedical
            },
            component: () => import('@/views/medical/load-reports/MedicalLoadReportsRankings.vue')
          },
          {
            path: '/medical/load-reports/individual/:teamId',
            name: 'MedicalLoadReportsIndividual',
            props: true,
            meta: {
              disallowAbort: true,
              pageInSearch: true,
              pageTitle: 'Load Report - Individual',
              permission: PERMISSIONS.read,
              securable: PERMISSIONS.AllMedical
            },
            component: () => import('@/views/medical/load-reports/MedicalLoadReportsIndividual.vue')
          }
        ]
      },

      // Default routes
      {
        path: '/medical/*',
        redirect: '/medical/all'
      },
      {
        path: '/medical',
        redirect: '/medical/all'
      }
    ]
  }
];
