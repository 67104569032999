export default {
  methods: {
    convertCmIntoIn(value) {
      if (!_.isNumber(value)) return value;
      const inches = value * 0.39370079;
      return Math.round(inches * 4) / 4; // return rounded to nearest .25 of an inch
    },

    // Converts dbo season to nba seasonYear
    // E.g. 2023  => "2023-24"
    convertSeasonToSeasonYear(season) {
      const nextSeason = (season + 1).toString().slice(2);
      return `${season}-${nextSeason}`;
    }
  }
};
