<template>
  <div>
    <div class="refresh-wrapper" :class="{ refreshing, 'show-wrapper': showWrapper }">
      <p class="refresh-text text-danger">There is a new version of CV available</p>
      <button class="btn btn-danger refresh-button" :tabindex="showWrapper ? 0 : -1" @click="refreshSite">
        <div v-if="refreshing" class="dis-f flex-a-c flex-j-c">
          <div class="cvi cvi-spinner"></div>
          <span>Refreshing...</span>
        </div>
        <span v-else>Please click here to reload site</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefreshRequest',

  data() {
    return {
      refreshing: false,
      showWrapper: false
    };
  },

  mounted() {
    window.addEventListener('refresh-site', this.showRefresh);
  },

  beforeDestroy() {
    window.removeEventListener('refresh-site', this.showRefresh);
  },

  methods: {
    refreshSite() {
      // Prevent infinite reloading in some situations
      if (this.refreshing) return;
      if (!this.sw) {
        console.error('No ServiceWorker attached. Try refreshing the page or see an administrator');
        this.showWrapper = false;
        return;
      }

      // Refreshing loader prevents user wondering what happened
      this.refreshing = true;
      this.sw.postMessage('skipWaiting');

      // Wait a full 2 seconds for the SW to refresh before reloading site
      setTimeout(() => {
        window.location.reload(); // ensures site is reloaded with new SW
      }, 2000);
    },

    showRefresh(event) {
      // Hide refresh button while other calls are ongoing
      if (this.$store.getters.getServicesInProgress.length) {
        setTimeout(() => {
          this.showRefresh(event);
        }, 1000);
      } else if (event && event.detail) {
        this.sw = event.detail.sw;
        this.showWrapper = true;
      }
    }
  }
};
</script>

<style scoped>
  .refresh-wrapper {
    align-items: center;
    background-color: var(--card-background);
    border-radius: 4px 4px 0 0;
    bottom: 0;
    box-shadow: 0 0 3px var(--box-shadow);
    display: flex;
    flex-direction: column;
    opacity: 0;
    padding: 10px 15px;
    position: fixed;
    right: 1.5em;
    transform: translate3d(0, 100px, 0);
    z-index: 2000;
  }

  .refresh-wrapper.show-wrapper {
    animation: fadeInUp forwards 0.5s;
  }

  .refresh-wrapper.refreshing button {
    pointer-events: none;
  }
  .refresh-wrapper.refreshing .text-danger {
    color: var(--on-card);
  }

  .refresh-text {
    font-size: 16px;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100px, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  @media (max-width: 991px) {
    .refresh-wrapper {
      right: calc(50vw - 155px)
    }
  }
</style>
