import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Personnel Wrapper
  {
    path: '/lists/',
    name: 'ListsRoot',
    props: true,
    component: () => import('@/views/lists/ListsRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'lists',
      securable: PERMISSIONS.List
    },

    // Children Routes
    children: [
      {
        path: '/lists/all',
        name: 'ListsAll',
        props: false,
        meta: {
          pageInSearch: true,
          pageTitle: 'Lists - All',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        },
        component: () => import('@/views/lists/ListsAll.vue')
      },
      {
        path: '/lists/recent',
        name: 'ListsRecent',
        props: false,
        meta: {
          pageInSearch: true,
          pageTitle: 'Lists - Recent',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.List
        },
        component: () => import('@/views/lists/ListsRecent.vue')
      },

      // Single List pages
      {
        path: '/lists/detail/:listId?',
        name: 'ListDetail',
        props: true,
        meta: {
          securable: PERMISSIONS.List,
          pageTitle: 'List',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/ListDetail.vue')
      },
      {
        path: '/lists/edit/:listId?',
        name: 'ListEdit',
        props: true,
        meta: {
          securable: PERMISSIONS.List,
          pageTitle: 'List Form',
          permission: PERMISSIONS.write
        },
        component: () => import('@/views/lists/ListEdit.vue')
      },

      // Single list, Print, pages
      {
        path: '/lists/aa/:listId',
        name: 'ListAA',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Analytic Assessment',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListAA.vue')
      },
      {
        path: '/lists/anotes/:listId',
        name: 'ListAnalyticNotes',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Analytic Notes',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListAnalyticNotes.vue')
      },
      {
        path: '/lists/combo/:listId',
        name: 'ListCombo',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Combo',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListCombo.vue')
      },
      {
        path: '/lists/commentaryIntel/:listId',
        name: 'ListCommentaryIntel',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Commentary - Intel',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListCommentaryIntel.vue')
      },
      {
        path: '/lists/commentaryScouting/:listId',
        name: 'ListCommentaryScouting',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Commentary - Scouting',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListCommentaryScouting.vue')
      },
      {
        path: '/lists/draft-models/:listId',
        name: 'ListDraftModels',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Draft Models',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListDraftModels.vue')
      },
      {
        path: '/lists/shot-charts/:listId',
        name: 'ListShotCharts',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Shot Charts',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListShotCharts.vue')
      },
      {
        path: '/lists/summaries/:listId',
        name: 'ListSummaries',
        props: true,
        meta: {
          disallowAbort: true,
          securable: PERMISSIONS.List,
          pageTitle: 'List Summary',
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/lists/print/ListSummaries.vue')
      },

      // Default routes
      {
        path: '/lists/*',
        redirect: '/lists/recent'
      },
      {
        path: '/lists',
        redirect: '/lists/recent'
      }
    ]
  }
];
