/**
 * Seconds into Minutes filter
 * Returns given seconds in minutes
 * e.g. 60 -> 1:00
 *
 * @param {integer} seconds
 * @returns {string} m:ss (minutes:seconds)
 */

import Vue from 'vue';

export default function secondsToMinutesFunction(seconds) {
  if (!seconds && seconds !== 0) return '';

  return `${Math.floor(seconds / 60)}:${_.padStart(Math.round(seconds % 60), 2, '0')}`;
}

Vue.filter('secondsToMinutes', secondsToMinutesFunction);
