/**
 * Elapsed Time filter
 * Returns the amount of time that has passed since the input
 *
 * @param {date} input (eg. '2019-07-01' or 1561957200000 [millis])
 * @returns {string} Date String ('x years ago')
 */

import Vue from 'vue';
import dayjs from 'dayjs';

export default function elapsedTimeFunction(input) {
  if (!input) return '';

  const output = dayjs(input).fromNow();
  // If input is not a date, 'NaN' will be at index 0
  if (output.search('NaN') === 0) return input;
  return output;
}

Vue.filter('elapsedTime', elapsedTimeFunction);
