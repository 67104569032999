/**
 * Inches into Display Height filter
 * Returns given inches in display format
 * e.g. 83.25 -> 6' 11.25"
 *
 * @param {integer} height
 * @returns {string} Height String
 */

import Vue from 'vue';

export default function inchesHeightFunction(height, useHyphen) {
  if (!_.isNumber(height)) return height;

  if (useHyphen) return `${Math.floor(height / 12)}–${_.round(height % 12, 2)}`;

  return `${Math.floor(height / 12)}' ${_.round(height % 12, 2)}"`;
}

Vue.filter('inchesHeight', inchesHeightFunction);
