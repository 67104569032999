import PERMISSIONS from '@/store/PermissionConstants';

export default [
  {
    path: '/analytics/',
    name: 'AnalyticsRoot',
    props: true,
    component: () => import('@/views/analytics/AnalyticsRoot.vue'),
    meta: {
      primary: 'analytics'
    },

    // Children Routes
    children: [
      // Index
      {
        path: '/analytics/index',
        name: 'AnalyticsIndex',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Analytics Index',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Analytics
        },
        component: () => import('@/views/analytics/AnalyticsIndex.vue')
      },

      // Models
      {
        path: '/analytics/explore',
        name: 'AnalyticsExplore',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Explore',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Analytics
        },
        component: () => import('@/views/analytics/AnalyticsExplore.vue')
      },

      // Draft Tools
      {
        path: '/analytics/draft-model',
        name: 'AnalyticsDraftModel',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Draft Model',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        },
        component: () => import('@/views/analytics/AnalyticsDraftModel.vue')
      },
      {
        path: '/analytics/draft-model-custom',
        name: 'AnalyticsDraftModelCustom',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Draft Model Custom',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        },
        component: () => import('@/views/analytics/AnalyticsDraftModelCustom.vue')
      },
      {
        path: '/analytics/current-class',
        name: 'AnalyticsCurrentClass',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Current Class',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.DraftModel
        },
        component: () => import('@/views/analytics/AnalyticsCurrentClass.vue')
      },
      {
        path: '/analytics/draft-picks-matrix',
        name: 'AnalyticsDraftPickMatrix',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Draft Picks - Analytics Matrix',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Analytics
        },
        component: () => import('@/views/analytics/AnalyticsDraftPickMatrix.vue')
      },
      {
        path: '/analytics/odds-available',
        name: 'AnalyticsOddsAvailable',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Mock Drafts - Odds Available',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Analytics
        },
        component: () => import('@/views/analytics/AnalyticsOddsAvailable.vue')
      },
      {
        path: '/analytics/draft-comps',
        name: 'AnalyticsDraftComps',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'aRole Draft Comps',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AnalyticsGroup
        },
        component: () => import('@/views/analytics/AnalyticsDraftComps.vue')
      },

      // Default routes
      {
        path: '/analytics/*',
        redirect: '/analytics/index'
      },
      {
        path: '/analytics',
        redirect: '/analytics/index'
      }
    ]
  }
];
