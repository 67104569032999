/**
 * PosId to Designation filter
 * Returns given designation
 * If short is true, returns first letter of designation
 * e.g. 3 -> Big
 * e.g. 3, short = true -> B
 * @param {integer} Position Id
 * @returns {string} designation
 */

import Vue from 'vue';

export default function posIdToDesignationFunction(posId, short) {
  if (!_.isNumber(posId)) return posId;

  const designationArray = ['', 'Guard', 'Wing', 'Big', 'Big', 'Big'];
  const desig = designationArray[posId] || 'Unknown Position';

  return short ? desig[0] : desig;
}

Vue.filter('posIdToDesignation', posIdToDesignationFunction);
