import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Game Search
       *********************************************************** */

      getGameSearch: {
        fn(params) {
          if (params.leagueIdEquals === 'GLeague') params.leagueIdEquals = 'NBADL';
          return this.apiCall('GET', 'getGameSearch', '/game/search', params);
        },
        meta: {
          cacheable: true,
          name: 'getGameSearch',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGame: {
        fn(gameId) {
          return this.apiCall('GET', 'getGame', '/game', { gameId });
        },
        meta: {
          cacheable: true,
          name: 'getGame',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGameV2: {
        fn(gameId) {
          const params = {
            embeds: ['gameStatus', 'gameTeams.team']
          };
          return this.apiCall('GET', 'getGameV2', `/game/${gameId}`, params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getGameV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGamesV2: {
        fn(params, completed) {
          if (completed) params.query.$and.push({ 'gameStatus.description': 'Completed' });

          return this.apiCall('GET', 'getGamesV2', '/game', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getGamesV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getMatchedGames: {
        fn(params) {
          return this.apiCall('GET', 'getMatchedGames', '/matchedGames', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getMatchedGames',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      /** **********************************************************
       * Box Scores
       *********************************************************** */

      getGamePlayerBoxScore: {
        fn(playerId, gameId, teamId, params) {
          return this.apiCall('GET', 'getGamePlayerBoxScore', `/gamePlayerBoxScore/${playerId}/${gameId}/${teamId}`, params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getGamePlayerBoxScore',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGamePlayerBoxScores: {
        fn(params) {
          return this.apiCall('GET', 'getGamePlayerBoxScores', '/gamePlayerBoxScore', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getGamePlayerBoxScores',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGameTeamBoxScores: {
        fn(params) {
          return this.apiCall('GET', 'getGameTeamBoxScores', '/gameTeamBoxScore', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getGameTeamBoxScores',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      /** **********************************************************
       * Game Misc
       *********************************************************** */

      getPlayerGames: {
        fn(params) {
          return this.apiCall('GET', 'getPlayerGames', '/player/games', params).then((d) => {
            _.each(d.data, (x) => {
              if (x.league === 'NBADL') {
                x.league = 'GLEAGUE';
                x.leagueDisplayName = 'GLeague';
                x.leagueId = 'GLEAGUE';
                x.leagueName = 'GLeague';
              }
            });

            return d;
          });
        },
        meta: {
          cacheable: true,
          name: 'getPlayerGames',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getPlayerGamesV2: {
        fn(playerId, completed, params) {
          const newQuery = _.cloneDeep(params);
          if (newQuery.query) {
            newQuery.query.$and.push({ 'gameTeams.gamePlayerBoxScores.plyId': playerId });
          } else {
            newQuery.query = { $and: [{ 'gameTeams.gamePlayerBoxScores.plyId': playerId }] };
          }

          if (completed) newQuery.query.$and.push({ 'gameStatus.description': 'Completed' });

          return this.apiCall('GET', 'getPlayerGamesV2', '/game', newQuery, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayerGamesV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getPlayersGamesV2: {
        fn(params) {
          return this.apiCall('GET', 'getPlayersGamesV2', '/game', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPlayersGamesV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getTeamGames: {
        fn(teamId, params) {
          if (params?.query?.$and.length) params.query.$and.push({ 'gameTeams.team.tmId': teamId });
          else params.query = { $and: [{ 'gameTeams.team.tmId': teamId }] };

          return this.apiCall('GET', 'getTeamGames', '/game', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getTeamGames',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGameRosters: {
        fn(gameId) {
          return this.apiCall('GET', 'getGameRosters', '/game/rosters', { gameId });
        },
        meta: {
          cacheable: true,
          name: 'getGameRosters',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getGameStarterBenchStats: {
        fn(gameId) {
          return this.apiCall('GET', 'getGameStarterBenchStats', '/game/starter-bench-stats', { gameId });
        },
        meta: {
          cacheable: true,
          name: 'getGameStarterBenchStats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      /** **********************************************************
       * NBA Schema Specific
       * NOTE - all data will be in nba format (e.g. PlayerIds !== dbo.PlyIds)
       *********************************************************** */
      getPbpGameData: {
        fn(params) {
          // return this.apiCall('GET', 'getPbpGameData', '/nbaEvents', params, null, null, true);
          return this.apiCall('GET', 'getPbpGameData', '/nbaPbps', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getPbpGameData',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getNbaGames: {
        fn(params) {
          return this.apiCall('GET', 'getNbaGames', '/nbaGames', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getNbaGames',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      },

      getEventTeam: {
        fn(params) {
          return this.apiCall('GET', 'getEventTeam', '/nbaVEventTeamExtended', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getEventTeam',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Game
        }
      }
    };
  }
};
