import Api from '@/mixins/Api';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Login/Verify/Logout/Extend
       *********************************************************** */

      postLogin: {
        fn(data, headers) {
          return this.apiCall('POST', 'postLogin', '/login', null, data, headers);
        },
        meta: {
          name: 'postLogin'
        }
      },

      postSSOLogin: {
        fn(data, headers) {
          return this.apiCall('POST', 'postSSOLogin', '/sso-login', null, JSON.stringify(data), headers, true);
        },
        meta: {
          name: 'postSSOLogin'
        }
      },

      postVerify: {
        fn(data, headers) {
          return this.apiCall('POST', 'postVerify', '/login/verify', null, data, headers);
        },
        meta: {
          name: 'postVerify'
        }
      },

      postLogout: {
        fn(headers) {
          return this.apiCall('POST', 'postLogout', '/logout', null, null, headers);
        },
        meta: {
          name: 'postLogout'
        }
      },

      postExtendSession: {
        fn() {
          return this.apiExtend();
        },
        meta: {
          name: 'postExtendSession'
        }
      }
    };
  }
};
