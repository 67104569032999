/**
 * Percentage filter
 * Returns given a number formatted as a percentage
 * e.g. 0.87 -> 87%
 *
 * Optionally, can take a denominator to determine
 * custom percentage
 * e.g. 0.87, 2 -> 43.5%
 *
 * @param {number} number
 * Optional @param {integer} places
 * Optional @param {number} denominator
 * Optional @param {string} prefix
 * @returns {string} Percentage number
 */

import Vue from 'vue';
import { format } from 'd3';

export default function percentageFunction(input, places, denominator, prefix) {
  if (!input && input !== 0) return input;
  if (_.isNaN(+input)) return input;

  if (!places && places !== 0) places = 2;
  if (!denominator) denominator = 1;

  const number = input / denominator;
  const f = format(`.${places}%`);

  let prefixText = '';
  if (prefix && number > 0) prefixText = '+';

  return `${prefixText}${f(number)}`;
}

Vue.filter('percentage', percentageFunction);
