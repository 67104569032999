import PERMISSIONS from '@/store/PermissionConstants';

export default {
  data() {
    return {
      /** **********************************************************
       * Focus List
       *********************************************************** */

      getFocusListCoach: {
        fn() {
          return this.apiCall('GET', 'getFocusListCoach', '/coach/focus-list');
        },
        meta: {
          name: 'getFocusListCoach',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CoachIntel
        }
      },

      /** **********************************************************
       * Coach Crud
       *********************************************************** */

      getCoach: {
        fn(params, coachId) {
          return this.apiCall('GET', 'getCoach', `/coaches/${coachId}`, params, null, null, true);
        },
        meta: {
          name: 'getCoach',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Coach
        }
      },

      updateCoach: {
        fn(updatedCoach) {
          if (updatedCoach.cwCoachMapSpurs.sourceCoachId) {
            return this.apiCall('PATCH', 'updateCoach', `/spursCoaches/${updatedCoach.cwCoachMapSpurs.sourceCoachId}`, null, JSON.stringify(updatedCoach), { 'Content-Type': 'application/json' }, true);
          }

          return this.apiCall('POST', 'updateCoach', '/spursCoaches', null, JSON.stringify(updatedCoach), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'updateCoach',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Coach
        }
      },

      /** **********************************************************
       * Summary CRUD
       *********************************************************** */

      getCoachSummary: {
        fn(coachId) {
          return this.apiCall('GET', 'getCoachSummary', '/coach/summary', { coachId });
        },
        meta: {
          name: 'getCoachSummary',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CoachIntel
        }
      },

      saveCoachSummary: {
        fn(data) {
          return this.apiCall('POST', 'saveCoachSummary', '/coach/summary', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveCoachSummary',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.CoachIntel
        }
      },

      /** **********************************************************
       * Analytics
       *********************************************************** */

      getCoachAnalytics: {
        fn(coachId) {
          return this.apiCall('GET', 'getCoachAnalytics', '/coach/analytical-assessment', { coachId });
        },
        meta: {
          name: 'getCoachAnalytics',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CoachIntel
        }
      }
    };
  }
};
