/**
 * Position Label filter
 * Returns given position in display format
 * e.g. 4 -> Big
 *
 * @param {integer} position
 * @returns {string} Position Label
 */

import Vue from 'vue';
import CONSTANTS from '@/store/Constants';

export default function positionLabelFunction(position) {
  if (!_.isInteger(position)) return position;

  return CONSTANTS.positions[`position${position}`] || 'Unknown Position';
}

Vue.filter('positionLabel', positionLabelFunction);
