import Vue from 'vue';

/**
 * Focuses element when bound to the DOM (i.e. on first load)
 * Use: <input v-focus />
 */
Vue.directive('focus', {
  inserted: (el) => {
    // Focus the element
    el.focus();
  }
});
