import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Video Wrapper
  {
    path: '/video/',
    name: 'VideoRoot',
    props: true,
    component: () => import('@/views/video/VideoRoot.vue'),
    meta: {
      primary: 'video'
    },

    // Children Routes
    children: [
      {
        path: '/video/tracking',
        name: 'VideoTracking',
        props: true,
        meta: {
          pageTitle: 'VideoTracking',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AnalyticsGroup
        },
        component: () => import('@/views/video/VideoTracking.vue')
      },
      {
        path: '/video/watch',
        name: 'VideoWatch',
        props: true,
        meta: {
          pageTitle: 'Video',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AnalyticsGroup
        },
        component: () => import('@/views/video/VideoWatch.vue')
      },

      // Default routes
      {
        path: '/video/*',
        redirect: '/video/watch'
      },
      {
        path: '/video',
        redirect: '/video/watch'
      }
    ]
  }
];
