/**
 * Locs filter
 * Can differentiate between the various locs types
 *  (alocs, locs, draft locs)
 * Returns given locs integer as a locs string
 * e.g. 9 -> Franchise
 * e.g {{ alocs | locs('a') }}
 *
 * @param {integer} locs
 * @param {String} type
 * @returns {string} Locs String
 */

import Vue from 'vue';
import CONSTANTS from '@/store/Constants.js';

export default function locsFunction(loc, typeInput) {
  if (!_.isInteger(loc) && _.isString(loc)) return loc;
  if (!_.isInteger(loc)) return '';

  let type = 'scouting';
  if (typeInput === 'a') type = 'analytic';
  else if (typeInput === 'aa') type = 'analyticAbbr';
  else if (typeInput === 'sa') type = 'scoutingAbbr';
  else if (typeInput === 'sra') type = 'scoutingReportsAbbr';
  else if (typeInput === 'd') type = 'draft';
  else if (typeInput === 'da') type = 'draftAbbr';

  const locsArray = _.find(CONSTANTS.locs, (l, k) => _.startsWith(k, type));

  if (loc >= locsArray.length || loc < 0) return 'NA';

  return locsArray[loc];
}

Vue.filter('locs', locsFunction);
