import PERMISSIONS from '@/store/PermissionConstants';

export default [
  {
    path: '/scouting/',
    name: 'ScoutingRoot',
    props: true,
    component: () => import('@/views/scouting/ScoutingRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'scouting',
      securable: PERMISSIONS.AllScouting
    },

    // Children Routes
    children: [
      {
        path: '/scouting/events',
        name: 'ScoutingEvents',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Scouting Events',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.ScoutingEvent
        },
        component: () => import('@/views/scouting/ScoutingEvents.vue')
      },
      {
        path: '/scouting/event/:id',
        name: 'ScoutingEvent',
        props: true,
        meta: {
          permission: PERMISSIONS.read,
          pageTitle: 'Scouting Event',
          securable: PERMISSIONS.PlayerScouting
        },
        component: () => import('@/views/scouting/ScoutingEvent.vue')
      },
      {
        path: '/scouting/my-reports',
        name: 'ScoutingMyReports',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'My Reports',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerScouting
        },
        component: () => import('@/views/scouting/ScoutingMyReports.vue')
      },
      {
        path: '/scouting/reports',
        name: 'ScoutingReports',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'All Reports',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerScouting
        },
        component: () => import('@/views/scouting/ScoutingReports.vue')
      },
      {
        path: '/scouting/report/:id?',
        name: 'ScoutingReport',
        props: true,
        meta: {
          disallowAbort: true,
          permission: PERMISSIONS.read,
          pageTitle: 'Scouting Report',
          securable: PERMISSIONS.PlayerScouting
        },
        component: () => import('@/views/scouting/ScoutingReport.vue')
      },

      /**
       *  Report Forms
       */
      {
        path: '/scouting/edit/:id?',
        name: 'ScoutingSingle',
        props: true,
        meta: {
          disallowAbort: true,
          pageInSearch: true,
          pageTitle: 'Scouting Report Form',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PlayerScouting
        },
        component: () => import('@/views/scouting/edit/ScoutingSingle.vue')
      },
      {
        path: '/scouting/event',
        name: 'ScoutingEventRoot',
        props: true,
        meta: {
          securable: PERMISSIONS.PlayerScouting,
          permission: PERMISSIONS.write
        },
        component: () => import('@/views/scouting/edit/ScoutingEventRoot.vue'),

        // Children Routes
        // All require an id
        children: [
          {
            path: '/scouting/event/summary/:id',
            name: 'ScoutingEventSummary',
            props: true,
            meta: {
              disallowAbort: true,
              pageInSearch: true,
              pageTitle: 'Event - Summary',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerScouting
            },
            component: () => import('@/views/scouting/edit/ScoutingEventSummary.vue')
          },
          {
            path: '/scouting/event/players/:id',
            name: 'ScoutingEventPlayers',
            props: true,
            meta: {
              disallowAbort: true,
              pageTitle: 'Event - Players',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerScouting
            },
            component: () => import('@/views/scouting/edit/ScoutingEventPlayers.vue')
          },
          {
            path: '/scouting/event/review/:id',
            name: 'ScoutingEventReview',
            props: true,
            meta: {
              disallowAbort: true,
              pageTitle: 'Event - Review',
              permission: PERMISSIONS.write,
              securable: PERMISSIONS.PlayerScouting
            },
            component: () => import('@/views/scouting/edit/ScoutingEventReview.vue')
          }
        ]
      },

      // Default routes
      {
        path: '/scouting/*',
        redirect: '/scouting/my-reports'
      },
      {
        path: '/scouting',
        redirect: '/scouting/my-reports'
      }
    ]
  }
];
