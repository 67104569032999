/**
 * Capitalize filter
 * Returns given string capitalized
 * e.g. player => Player
 *
 * @param {string} value
 * @returns {string}
 */

import Vue from 'vue';

export default function capitalizeFunction(value) {
  if (!_.isString(value)) return value;

  return _.capitalize(value);
}

Vue.filter('capitalize', capitalizeFunction);
