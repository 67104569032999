/**
 * Date filter
 * Returns given millis date in display format
 * e.g. Jul 19, 1985
 *
 * @param {integer} millis
 * @returns {string} Date String
 */

import Vue from 'vue';
import dayjs from 'dayjs';

export default function dateFunction(millis, type, utc) {
  if (!millis && millis !== 0) return millis;
  if (_.isNaN(+millis)) return millis;
  if (!_.isFinite(+millis)) return millis;

  const date = utc ? dayjs.utc(millis) : dayjs(millis);

  if (type === 'shortDate') return date.format('M/D/YY'); // 1/31/19
  if (type === 'shortTime') return date.format('h:mm a z'); // 11:08 am CST
  if (type === 'short') return date.format('M/D/YY h:mm a'); // 1/31/19 11:08 am
  if (type === 'mediumDate') return date.format('MMM D, YYYY'); // Jan 31, 2019
  if (type === 'ordinalDate') return date.format('MMM Do, YYYY'); // Jan 31st, 2019
  if (type) return date.format(type);
  return date.format('MMM DD, YYYY'); // Jan 31, 2019
}

Vue.filter('date', dateFunction);
