/**
 * Ordinal filter
 *  When a number is taken as input from the user,
 *    ordinal will take that number and transform it,
 *    if the number ends in 1, 2, 3 output:
 *    1 -> 1st
 *    2 -> 2nd
 *    3 -> 3rd
 *    unless number ends in 11, 12, 13.
 *    Otherwise add 'th' to other numbers
 * @usage
 *
 * {{ leader[leaderStat.rankProp] | ordinal }}
 *
 * @output
 *    1st, 2nd, 3rd, nth
 *
 * @param {integer} number
 * @returns {string} Ordinal number
 */

import Vue from 'vue';

export default function ordinalFunction(input) {
  if (!input && input !== 0) return input;
  if (!_.isNumber(+input) || !_.isFinite(+input)) return input;

  const roundedInput = Math.round(+input);
  const inputString = roundedInput.toString();
  switch (inputString.slice(-1)) {
    case '1':
      return inputString.slice(-2) === '11' ? `${roundedInput}th` : `${roundedInput}st`;
    case '2':
      return inputString.slice(-2) === '12' ? `${roundedInput}th` : `${roundedInput}nd`;
    case '3':
      return inputString.slice(-2) === '13' ? `${roundedInput}th` : `${roundedInput}rd`;
    default:
      return `${roundedInput}th`;
  }
}

Vue.filter('ordinal', ordinalFunction);
