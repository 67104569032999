/**
 * Age From Birthdate filter
 * Returns age from given birthdate in millis format
 * e.g. 820346400000 (Dec 30,1995) -> 25 (as of 9/14/21)
 *
 * @param {integer} input
 * @returns {integer} Age
 */

import Vue from 'vue';
import dayjs from 'dayjs';

export default function ageFromBirthdateFunction(input) {
  if (!input || !_.isFinite(input)) {
    return input;
  }

  return dayjs().diff(input, 'years');
}

Vue.filter('ageFromBirthdate', ageFromBirthdateFunction);
