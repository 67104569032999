import Vue from 'vue';

function loadImage(el, binding, index) {
  const srcToUse = binding.value[index];

  // If backgroundImage, use a dummy img to get onload, onerror events
  if (binding.arg === 'background-image') {
    const img = document.createElement('img');
    img.setAttribute('src', srcToUse);

    img.addEventListener('load', () => {
      el.style.setProperty('background-image', `url("${srcToUse}")`, 'important');
      img.remove();
    }, { once: true });

    img.addEventListener('error', () => {
      img.remove();

      // Maximum of 5 tries. Each try is a different type of file to download (e.g. [*.svg, *.png, default.svg, default.png])
      if (index > 5) return;

      index += 1;
      loadImage(el, binding, index);
    }, { once: true });
  } else {
    el.setAttribute('src', srcToUse);
    el.addEventListener('error', () => {
      // Clear the error event so we don't get into an infinite loop
      el.onerror = null;

      // Maximum of 5 tries
      if (index > 5) return;

      index += 1;
      loadImage(el, binding, index);
    }, { once: true });
  }
}

/**
 * Lazy Loader for Images
 * (only supports PNG images)
 * Is Print CSS aware - will load all images if on Print
 * Supports backgroundImage and Image with src
 *
 * Adapted from: https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
 */
Vue.directive('lazyload', {
  update(el, binding) {
    loadImage(el, binding, 0);
  },

  inserted(el, binding) {
    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage(el, binding, 0);
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0'
      };

      // OPTIMIZE - Is this creating one IO per image? Should it create a single one to handle all images?
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }

    // Check for print and if window becomes print focused, load image immediately
    const printMedia = window.matchMedia('print');
    printMedia.onchange = () => {
      if (printMedia.matches) loadImage(el, binding, 0);
    };

    // If browser has Observer and not in Print mode
    if (window.IntersectionObserver && !printMedia.matches) createObserver();
    else loadImage(el, binding, 0);
  }
});
