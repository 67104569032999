import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Reports
       *********************************************************** */

      getReport: {
        fn(documentId) {
          return this.apiCall('GET', 'getReports', '/document', { documentId });
        },
        meta: {
          name: 'getReport',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Document
        }
      },

      getReportAsJson: {
        fn(documentId) {
          return this.apiCall('GET', 'getReportAsJson', '/document/json', { documentId });
        },
        meta: {
          name: 'getReportAsJson',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Document
        }
      },

      downloadDocument: {
        fn(documentId, filename) {
          return this.apiDownload('downloadDocument', '/document/download', { documentId }, filename);
        },
        meta: {
          name: 'downloadDocument',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Document
        }
      },

      getReports: {
        fn(page) {
          return this.apiCall('GET', 'getReports', '/documents', this.setPage({}, page, 50));
        },
        meta: {
          name: 'getReports',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Document
        }
      },

      /** **********************************************************
       * Postgame / Faxback Manual Execution
       *********************************************************** */

      generatePostgame: {
        fn(gameId) {
          return this.apiCall('GET', 'generatePostgame', '/game-report/execute-postgame', { gameId });
        },
        meta: {
          name: 'generatePostgame',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.PostgameReport
        }
      },

      generateFaxback: {
        fn(homeTeamId, awayTeamId) {
          return this.apiCall('GET', 'generateFaxback', '/game-report/execute-faxback', {
            homeTeamId,
            awayTeamId
          });
        },
        meta: {
          name: 'generateFaxback',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Document
        }
      },

      /** **********************************************************
       * Pregame
       *********************************************************** */

      getPregameReports: {
        fn(params) {
          return this.apiCall('GET', 'getPregameReports', '/pregameReportFiles', params, null, null, true);
        },
        meta: {
          name: 'getPregameReports',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Document
        }
      },

      generatePregame: {
        fn(teamId) {
          return this.apiCall('POST', 'generatePregame', '/pregameTrigger', null, JSON.stringify({ tmId: teamId }), { 'Content-Type': 'application/json' }, true);
        },
        meta: {
          name: 'generatePregame',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Document
        }
      }
    };
  }
};
