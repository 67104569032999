import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Personnel Wrapper
  {
    path: '/admin/',
    name: 'AdminRoot',
    props: true,
    component: () => import('@/views/admin/AdminRoot.vue'),
    meta: {
      primary: 'admin'
    },

    // Children Routes
    children: [
      {
        path: '/admin/groups',
        name: 'AdminManageGroups',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Manage Groups',
          securable: PERMISSIONS.UserAdmin,
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/admin/AdminManageGroups.vue')
      },
      {
        path: '/admin/group/:notificationGroupId?',
        name: 'AdminEditGroup',
        props: true,
        meta: {
          pageTitle: 'Edit Group',
          securable: PERMISSIONS.UserAdmin,
          permission: PERMISSIONS.write
        },
        component: () => import('@/views/admin/AdminEditGroup.vue')
      },
      {
        path: '/admin/roles',
        name: 'AdminManageRoles',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Manage Roles',
          securable: PERMISSIONS.UserAdmin,
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/admin/AdminManageRoles.vue')
      },
      {
        path: '/admin/role/:roleId?',
        name: 'AdminEditRole',
        props: true,
        meta: {
          pageTitle: 'Edit Role',
          securable: PERMISSIONS.UserAdmin,
          permission: PERMISSIONS.write
        },
        component: () => import('@/views/admin/AdminEditRole.vue')
      },
      {
        path: '/admin/users',
        name: 'AdminManageUsers',
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: 'Manage Users',
          securable: PERMISSIONS.UserAdmin,
          permission: PERMISSIONS.read
        },
        component: () => import('@/views/admin/AdminManageUsers.vue')
      },
      {
        path: '/admin/user/:userId?',
        name: 'AdminEditUser',
        props: true,
        meta: {
          pageTitle: 'Edit User',
          securable: PERMISSIONS.UserAdmin,
          permission: PERMISSIONS.write
        },
        component: () => import('@/views/admin/AdminEditUser.vue')
      },

      // Default routes
      {
        path: '/admin/*',
        redirect: '/'
      },
      {
        path: '/admin',
        redirect: '/'
      }
    ]
  }
];
