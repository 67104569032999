import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Commentary Wrapper
  {
    path: '/commentary/',
    name: 'CommentaryRoot',
    props: true,
    component: () => import('@/views/commentary/CommentaryRoot.vue'),
    meta: {
      permission: PERMISSIONS.read,
      primary: 'commentary',
      securable: PERMISSIONS.AllCommentary
    },

    // Children Routes
    children: [
      {
        path: '/commentary/all',
        name: 'CommentaryAll',
        props: true,
        meta: {
          disallowAbort: true,
          permission: PERMISSIONS.read,
          pageInSearch: true,
          pageTitle: 'Commentary',
          securable: PERMISSIONS.AllCommentary
        },
        component: () => import('@/views/commentary/CommentaryAll.vue')
      },
      {
        path: '/commentary/attachment/:id?',
        name: 'CommentaryAttachment',
        props: true,
        meta: {
          disallowAbort: true,
          optionTitle: 'Attachment',
          pageTitle: 'Attachment',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AttachmentCommentary
        },
        component: () => import('@/views/commentary/CommentaryAttachment.vue')
      },
      {
        path: '/commentary/attachments',
        name: 'CommentaryAttachments',
        props: true,
        meta: {
          optionTitle: 'Attachment',
          pageInSearch: true,
          pageTitle: 'Attachments',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AttachmentCommentary,
          type: 'attachments'
        },
        component: () => import('@/views/commentary/CommentaryAttachments.vue')
      },
      {
        path: '/commentary/blotter/:id?',
        name: 'CommentaryBlotter',
        props: true,
        meta: {
          optionTitle: 'Blotter',
          pageTitle: 'Blotter',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerBlotter
        },
        component: () => import('@/views/commentary/CommentaryBlotter.vue')
      },
      {
        path: '/commentary/blotters',
        name: 'CommentaryBlotters',
        props: true,
        meta: {
          optionTitle: 'Blotter',
          pageInSearch: true,
          pageTitle: 'Blotters',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerBlotter,
          type: 'blotters'
        },
        component: () => import('@/views/commentary/CommentaryBlotters.vue')
      },
      {
        path: '/commentary/discussion/:id?',
        name: 'CommentaryDiscussion',
        props: true,
        meta: {
          optionTitle: 'Discussion',
          pageTitle: 'Discussion',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Discussion
        },
        component: () => import('@/views/commentary/CommentaryDiscussion.vue')
      },
      {
        path: '/commentary/discussions',
        name: 'CommentaryDiscussions',
        props: true,
        meta: {
          optionTitle: 'Discussion',
          pageInSearch: true,
          pageTitle: 'Discussions',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Discussion,
          type: 'discussions'
        },
        component: () => import('@/views/commentary/CommentaryDiscussions.vue')
      },
      {
        path: '/commentary/journal/:id?',
        name: 'CommentaryJournal',
        props: true,
        meta: {
          optionTitle: 'Journal',
          pageTitle: 'Journal',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.JournalCommentary
        },
        component: () => import('@/views/commentary/CommentaryJournal.vue')
      },
      {
        path: '/commentary/journals',
        name: 'CommentaryJournals',
        props: true,
        meta: {
          optionTitle: 'Journal',
          pageInSearch: true,
          pageTitle: 'Journals',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.JournalCommentary,
          type: 'journals'
        },
        component: () => import('@/views/commentary/CommentaryJournals.vue')
      },
      {
        path: '/commentary/media-report/:id?',
        name: 'CommentaryMediaReport',
        props: true,
        meta: {
          optionTitle: 'Media Report',
          pageTitle: 'Media Report',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.MediaCommentary
        },
        component: () => import('@/views/commentary/CommentaryMediaReport.vue')
      },
      {
        path: '/commentary/media-reports',
        name: 'CommentaryMediaReports',
        props: true,
        meta: {
          optionTitle: 'Media Report',
          pageInSearch: true,
          pageTitle: 'Media Reports',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.MediaCommentary,
          type: 'media-reports'
        },
        component: () => import('@/views/commentary/CommentaryMediaReports.vue')
      },

      /**
       *  Report Forms
       */
      {
        path: '/commentary/edit/attachment/:id?',
        name: 'CommentaryEditAttachment',
        props: true,
        meta: {
          optionTitle: 'Attachment',
          permission: PERMISSIONS.write,
          pageTitle: 'Edit Attachment',
          securable: PERMISSIONS.PlayerAttachment
        },
        component: () => import('@/views/commentary/edit/CommentaryEditAttachment.vue')
      },
      {
        path: '/commentary/edit/blotter/:id?',
        name: 'CommentaryEditBlotter',
        props: true,
        meta: {
          optionTitle: 'Blotter',
          permission: PERMISSIONS.write,
          pageTitle: 'Edit Blotter',
          securable: PERMISSIONS.PlayerBlotter
        },
        component: () => import('@/views/commentary/edit/CommentaryEditBlotter.vue')
      },
      {
        path: '/commentary/edit/discussion/:id?',
        name: 'CommentaryEditDiscussion',
        props: true,
        meta: {
          optionTitle: 'Discussion',
          permission: PERMISSIONS.write,
          pageTitle: 'Edit Discussion',
          securable: PERMISSIONS.Discussion
        },
        component: () => import('@/views/commentary/edit/CommentaryEditDiscussion.vue')
      },
      {
        path: '/commentary/edit/journal/:id?',
        name: 'CommentaryEditJournal',
        props: true,
        meta: {
          optionTitle: 'Journal',
          permission: PERMISSIONS.write,
          pageTitle: 'Edit Journal',
          securable: PERMISSIONS.JournalCommentary
        },
        component: () => import('@/views/commentary/edit/CommentaryEditJournal.vue')
      },
      {
        path: '/commentary/edit/media-report/:id?',
        name: 'CommentaryEditMediaReport',
        props: true,
        meta: {
          optionTitle: 'Media Report',
          permission: PERMISSIONS.write,
          pageTitle: 'Edit Media Report',
          securable: PERMISSIONS.MediaCommentary
        },
        component: () => import('@/views/commentary/edit/CommentaryEditMediaReport.vue')
      },

      // Default routes
      {
        path: '/commentary/*',
        redirect: '/commentary/all'
      },
      {
        path: '/commentary',
        redirect: '/commentary/all'
      }
    ]
  }
];
