import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      downloadAttachment: {
        fn(downloadLink, filename) {
          return this.apiDownloadDirect('downloadAttachment', downloadLink, filename);
        },
        meta: {
          name: 'downloadAttachment',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AgentAttachment
        }
      },

      uploadAttachment: {
        fn(file) {
          return this.apiUpload('uploadAttachment', '/fileInfo/upload', {}, file, true);
        },
        meta: {
          name: 'uploadAttachment',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Attachment
        }
      }
    };
  }
};
