import Vue from 'vue';

// Third Party Imports
import _ from 'lodash'; // Lodash
import * as uiv from 'uiv'; // Bootstrap
import * as d3 from 'd3'; // d3
import VueGtag from 'vue-gtag'; // Google Analytics

// Essentials
import App from './App.vue';
import router from './router';
import store from './store';

// Third Party Configs
import DayJSMixins from './packages/DayJSMixins';
import LodashMixins from './packages/LodashMixins';
import { hexbinInit, tooltipInit } from './packages/d3Addons';

// Filters
import './filters/AgeFromBirthdate';
import './filters/Capitalize';
import './filters/CommentaryName';
import './filters/CommentaryTitle';
import './filters/Currency';
import './filters/Date';
import './filters/DecimalPlaces';
import './filters/DesignationToPosId';
import './filters/DraftValue';
import './filters/DynamicFilter';
import './filters/ElapsedTime';
import './filters/InchesHeight';
import './filters/IntelRelationship';
import './filters/Locs';
import './filters/NbaPercentage';
import './filters/NoLeadingZero';
import './filters/Number';
import './filters/Ordinal';
import './filters/Percentage';
import './filters/PhoneNumber';
import './filters/PosIdToDesignation';
import './filters/PositionLabel';
import './filters/SecondsToMinutes';

// Directives
import './directives/Css';
import './directives/Focus';
import './directives/Images';
import './directives/Input';
import './directives/Lists';
import './directives/Scroll';
import './directives/Services';

// Cache Service Worker
import './registerServiceWorker';

// Turn off production tips
Vue.config.productionTip = false;

// Error handling
if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'development') {
  Vue.config.errorHandler = (err, vm, info) => {
    console.group();
    console.error('Error', err); // err: error trace
    console.error('Component', vm); // vm: component in which error occured
    console.error('Info', info); // info: Vue specific error information such as lifecycle hooks, events etc.
    console.groupEnd();
  };
}

// Use Bootstrap
Vue.use(uiv);

// Use Google Analytics
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_ga !== '123') {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ga }
  }, router);
}

// Initialise store before Vue (and router) is created
store.commit('initialiseStore');

// Set up global event bus
Vue.prototype.$eventHub = new Vue();

// Create Vue object
const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// Set Globals
window._ = _;
window.d3 = d3;

// Add in extras to d3
hexbinInit();
tooltipInit();

// Add in lodash mixins
LodashMixins();

// Add in dayjs mixins
DayJSMixins();

// App only needs to be available during E2E (Cypress) tests
if (window.Cypress) {
  window.app = app;
}
