import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Permissions - ACE / Securables
       *********************************************************** */

      getCurrentUserPermissions: {
        fn() {
          return this.apiCall('GET', 'getCurrentUserPermissions', '/acl/aces');
        },
        meta: {
          name: 'getCurrentUserPermissions',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UNRESTRICTED
        }
      },

      getSecurables: {
        fn() {
          return this.apiCall('GET', 'getSecurables', '/acl/securables');
        },
        meta: {
          name: 'getSecurables',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      },

      getAces: {
        fn(secureId) {
          return this.apiCall('GET', 'getAces', '/acl/aces', { secureId });
        },
        meta: {
          name: 'getAces',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.UserAdmin
        }
      }
    };
  }
};
