/**
 * Handles all Roles/Permissions events
 */

import PERMISSIONS from '@/store/PermissionConstants';

const config = {
  offlineLocation: 'san-antonio-spurs-offline',
  roleLocation: 'san-antonio-spurs-role',
  storageLocation: 'san-antonio-spurs-permissions'
};

/**
 * Returns true if a possessed permission is greater than or equal to the required.
 *
 * READ < WRITE < DELETE
 *
 * @param possessedPermission - user's permission
 * @param requiredPermission - required permission
 * @returns {boolean}
 */
function permissionMeetsRequirement(possessedPermission, requiredPermission) {
  switch (requiredPermission) {
    case 'DELETE':
      return possessedPermission === PERMISSIONS.delete;
    case 'WRITE':
      return possessedPermission === PERMISSIONS.write
        || possessedPermission === PERMISSIONS.delete;
    case 'READ':
      return possessedPermission === PERMISSIONS.read
        || possessedPermission === PERMISSIONS.write
        || possessedPermission === PERMISSIONS.delete;
    default:
      return false;
  }
}

/**
 * Returns whether or not a given access level meets the requirement
 *
 * For ownership required levels, checks to make sure userId and contentCreator are the same
 *
 * @param accessLevel - user's access level
 * @param userId
 * @param owner - user who created the original content
 * @returns {boolean}
 */
function accessLevelMeetsRequirement(accessLevel, userId, owner) {
  if (accessLevel === 'ALL') return true;

  if (accessLevel === 'RAWO' || accessLevel === 'OWN') {
    return _.toLower(userId) === _.toLower(owner);
  }

  return false;
}

/**
 * Determines if user is allowed offline access
 *
 * @param permissions - list of user's permissions
 * @returns {boolean}
 */
function allowedOffline(permissions) {
  const commentarySecurables = PERMISSIONS.AllCommentary;
  return _.some(permissions, p => _.includes(commentarySecurables, p.securableName) && permissionMeetsRequirement(p.permission, 'WRITE'));
}

export default {
  namespaced: true,

  // Actual State stored
  state: {
    permissionsPossessed: [],
    role: null
  },

  mutations: {
    // Initialise Permissions from localStorage keys if available
    initialise(state) {
      state.permissionsPossessed = JSON.parse(localStorage.getItem(config.storageLocation)) || [];
      state.role = localStorage.getItem(config.roleLocation) || null;
    },

    // Permissions
    updatePermissions(state, newPermissions) {
      localStorage.setItem(config.storageLocation, JSON.stringify(newPermissions));
      state.permissionsPossessed = newPermissions;
      this.commit('permissions/setOfflinePermissions', newPermissions);
    },
    removePermissions(state) {
      localStorage.removeItem(config.storageLocation);
      state.permissionsPossessed = [];
    },

    // Offline Specific Permissions
    setOfflinePermissions(state, newPermissions) {
      const username = this.getters['security/getUsername'];
      // TODO - cull permissions at least some
      if (allowedOffline(newPermissions)) {
        localStorage.setItem(`${config.offlineLocation}:${username}`, JSON.stringify(newPermissions));
      }
    },

    // Role
    updateRole(state, newRole) {
      localStorage.setItem(config.roleLocation, newRole);
      state.role = newRole;
    },
    removeRole(state) {
      localStorage.removeItem(config.roleLocation);
      state.role = null;
    }
  },

  actions: {
    setPermissions({ commit }, permissions) {
      if (permissions) commit('updatePermissions', permissions);
      else commit('removePermissions');
    },

    setRole({ commit }, role) {
      if (role) commit('updateRole', role);
      else commit('removeRole');
    }
  },

  getters: {
    getOfflinePermissions: () => (username) => {
      const offlinePermissions = localStorage.getItem(`${config.offlineLocation}:${username}`);
      if (!offlinePermissions || offlinePermissions.length === 0) return false;
      return JSON.parse(offlinePermissions);
    },
    getPermissions: state => state.permissionsPossessed,
    getRole: state => state.role,

    hasPermission: (state, getters, rootState) => (securableRequired, permissionRequired, owner) => {
      if (securableRequired === PERMISSIONS.UNRESTRICTED) return true;

      const { username } = rootState.security;

      return _.some(state.permissionsPossessed, (p) => {
        const securablesArray = _.isArray(securableRequired) ? securableRequired : [securableRequired];
        const securableOk = _.includes(securablesArray, p.securableName);
        const permissionOk = permissionMeetsRequirement(p.permission, permissionRequired);
        const accessLevelOk = owner ? accessLevelMeetsRequirement(p.accessLevel, username, owner) : true;

        return securableOk && permissionOk && accessLevelOk;
      });
    }
  }
};
